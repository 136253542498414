import React,{useState,useEffect} from 'react'
import './style.css'

import cartaoanjo from '../../images/iconCartaoAnjo.png'

import walletIcon from '../../images/walletIcon.png'
import handShakeIcon from '../../images/handShakeIcon.png'
import walletIcon2 from '../../images/wallet2Icon.png'
import exitIcon from '../../images/exitIcon.png'

import api from '../../services/api'


const Sidebar2 = ({checked,page}) =>{

    const [pages,setPages] = useState(false)
    const [expire,setExpire] = useState()
    const [token,setToken] = useState()

    async function getDataUser(){

        let data = await api.get('/recipient')       
        setExpire(data.data.expire)


        if(expire == null){

            setPages(false)

        }


        if(expire != null){

            setPages(true)

        }
        
    }

    const exitApp = () =>{

        localStorage.removeItem('@TOKEN')
        window.location.href="/"

    }

    
    async function getToken(){

        const token = localStorage.getItem('@TOKEN')
        setToken(token)
    }

    useEffect(() =>{
        getToken()

        getDataUser()
    },[])

    return(

        <div className="sidebar">
        
        <div className="icon_card_container" onClick={() => expire != null ? window.location.href="/dashboard" : window.location.href="/"}>
        <img src={cartaoanjo} className="icon_card"/>
        </div>
        
        <nav>
        <ul>

        {token == undefined 
        ? 
        checked == 'partners'?<div className="flex_icons"><img src={handShakeIcon}/><li className="checked" onClick={() => window.location.href="partners"} >Parceiros</li></div> :<div className="flex_icons"><img src={handShakeIcon}/><li  onClick={() => window.location.href="partners"} >Parceiros</li></div>

        :
        
        checked == 'home'?<div className="flex_icons"><img src={walletIcon}/><li className="checked" onClick={() => window.location.href="dashboard"} >Inicio</li></div> :<div className="flex_icons"><img src={walletIcon}/><li onClick={() => window.location.href="dashboard"}  >Inicio</li></div>         
       
       }

        {token == undefined 
        ?
            ''
        :
        checked == 'account'?<div className="flex_icons"><img src={walletIcon2}/><li onClick={() => window.location.href="profile"} className="checked" >Minha conta </li></div> :<div className="flex_icons"><img src={walletIcon2}/><li onClick={() => window.location.href="profile"} >Minha conta</li></div>

        }

         {token == undefined 
        ?
            ''
        :
        checked == 'partners'?<div className="flex_icons"><img src={handShakeIcon}/><li className="checked" onClick={() => window.location.href="partners"} >Parceiros</li></div> :<div className="flex_icons"><img src={handShakeIcon}/><li  onClick={() => window.location.href="partners"} >Parceiros</li></div>

        }

       

        </ul>
        </nav>

        {token != undefined 
        
        ?
    
        <>
        <div className="exit">

        <img src={exitIcon} onClick={() => exitApp()}/>
        <p onClick={() => exitApp()}>Sair</p>

        </div>

        </>


        :

            ''
        }

    


    
        </div>

    )

}

export default Sidebar2