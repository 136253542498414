import React from 'react'

import setaIcon from '../../images/setaIcon2.png'

import './style.css'

const PlansMobileComponent = ({type,value,id}) =>{

    const redirectToPaymentPage = (e) =>{

        localStorage.setItem('@plan_mobile',e)
        localStorage.setItem('@plan_mobile_value',value)
        window.location.href="/payment-card-mobile"

    }

    
    return(

        <div className="plans_mobile" onClick={() => redirectToPaymentPage(id)}>

        <div className="column1_plans_mobile">

        <h2>Cartão Anjo</h2>
        <h2>{type == 'Y' ? 'Anual' : 'Mensal'}</h2>

        </div>
            
        <div className="column2_plans_mobile">

        <h2>{value.toLocaleString('pt-br',{style:'currency',currency:'BRL'})}</h2>
        <img src={setaIcon}/>

        </div>


        </div>

    )

}

export default PlansMobileComponent