import React from 'react'

import './style.css'

import iconCartaoAnjo from '../../images/iconCartaoAnjo.png'
import handIcon from '../../images/handIcon.png'
import ButtonMobile from '../../components/ButtonMobile'

const SucessPageMobile = () =>{

    return(

        <div className="container_sucess_page_mobile">

        <div className="icon_sucess_page_mobile">

        <img src={iconCartaoAnjo}/>

        </div>

        <div className="sucess_message_page_mobile">
        <h2>Tudo certo</h2>

        <p>Obrigado pelas informações, você <br/> está a um passo de desfrutar de todos os <br/> nossos benefícios, para confirmar o <br/> seu cadastro, acesse o link enviado por <br/> e-mail.</p>
        </div>

        <div className="icon_handle_succes_page_mobile">
        <img src={handIcon}/>
        <ButtonMobile text="Ir para tela de acesso" width="70%" onClick={() => window.location.href="/login"}/>
        </div>




        </div>

    )

}

export default SucessPageMobile