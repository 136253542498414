import React,{useEffect,useState} from 'react'

import DashboardsMobile from '../../components/Dashboards-Mobile'
import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'
import BottomBarMobile from '../../components/BottomBarMobile'

import IndicationsMobileComponent from '../../components/IndicationsMobileComponent'

import './style.css'
import Api from '../../services/api'
import verifyUser from '../../functions/verifyUser'

const IndicationsMobile = () =>{
    
    const [indications,setIndications] = useState([])

    async function getDataIndications(){

        const data = await Api.get('/commisions')
        setIndications(data.data)
        
    }



    useEffect(() =>{
        verifyUser()

        getDataIndications()
     
        
    },[])

    return(

        <>
        <HeaderDashboardMobile page="dashboard" />

        <div className="historic_transactions_dashboard_mobile">
        <h2>Lista de Indicações</h2>
        <div className="container_dashboards_historic_transactions_mobile">
        <DashboardsMobile indications={false}/>
        
        <div className="data_historic_transactions">

        <p>Lista de indicados</p>
   
        {indications.map(item => {


        return <IndicationsMobileComponent key={item.id} name={item.payment.member.user.name} plan={item.plan_id} comission={item.value} state={item.payment.member.user.status} type={item.payment.type}/>


        })}


        </div>

        </div>


        </div>
        <BottomBarMobile/>

        </>

    )

}

export default IndicationsMobile