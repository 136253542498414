import React,{useState,useEffect} from 'react'

import Button from '../../components/Button'
import Input from '../../components/Input'
import InputMasked from '../../components/InputMasked'
import Select from '../../components/Select'

import './style.css'
import iconCartaoAnjo from '../../images/iconCartaoAnjo.png'
import cartaoanjo from '../../images/cartaoAnjo.png'

import handIcon from '../../images/handIcon.png'

import api from '../../services/api'

const SucessRegister = () =>{

    const [mail,setMail] = useState()
    const [password,setPassword] = useState()
    const [messageError,setMessageErrorLogin] = useState()



    async function logIn(){


            const response = await api.post('/login',{
            email: mail,
            password:password,
            })
          
            if(response.status == 200){
              
              localStorage.setItem('@TOKEN',response.data.access_token)
              setMessageErrorLogin('')
              setMail('')
              setPassword('')
              const data = await api.get('/recipient')
                            
              let verifyUserExistsInDatabase = data.data.expire
              
              if(verifyUserExistsInDatabase){
                window.location.href='dashboard'
              }

              if(!verifyUserExistsInDatabase){

                window.location.href="payment-card"

              }

            }

            if(response.status == 401){
            setMessageErrorLogin('Desculpe, endereço de e-mail ou senha incorretos. Por favor, tente novamente')
            }
            


        
    }


    return(

        

        <>
        <div className="container_fluid">

        
            <div className="column1">
            <img src={cartaoanjo} className="icon_card"/>
            
            <h1 className="textPrimary">Cartão Anjo</h1>
            <h2 className="textSecondary">Cuida da sua saúde</h2>
            <p className="textDescription">O CARTÃO ANJO CLUB é para todos. Não existem <br/> limitações, impedimentos de idade, carências,<br/> restrições a grau de parentesco, gestantes, doenças<br/> preexistentes ou crônicas.</p>
            <a href='https://cartaoanjo.com.br/' className="info" target='_blank'>Saiba mais</a>
            
            <div className="buttons">
            <Button text="Já sou cadastrado" onClick={() => window.location.href="/login"}/>
            <Button text="Cadastre-se" onClick={() => window.location.href="/register"}/>
            <Button text="Parceiros" onClick={() => window.location.href="/partners"}/>
            </div>


            </div>

            <div className="column2">
    
            <div className="container_inputs_logIn">
            <img src={iconCartaoAnjo} className="icon_container_inputs_login"/>
            <h2>Tudo certo</h2>
            <p>Obrigado pelas informações, você está a um passo de  desfrutar de <br/> todos os nossos benefícios, para confirmar o seu cadastro, acesse o <br/> link enviado por e-mail.</p>

            <img src={handIcon} className="handleIcon"/>
            
            <Button text="Ir para tela de acesso" onClick={() => window.location.href="/login" }/>
            
            </div>

            </div>

        </div>

        </>



    )


}

export default SucessRegister