import React, { useState, useEffect } from 'react'

import Button from '../../components/Button'


import './style.css'
import iconCartaoAnjo from '../../images/iconCartaoAnjo.png'
import cartaoanjo from '../../images/cartaoAnjo.png'
import arrowIcon from '../../images/setaIcon.jpeg'

import api from '../../services/api'
import hanshakeIcon from '../../images/punhosIcon2.png'
import facebookIcon from '../../images/facebookIcon.png'

import FacebookLogin from 'react-facebook-login';



const LogIn = () => {
  const url_atual = window.location.href

  const statusMailChange = url_atual.indexOf('msg')

  const [mail, setMail] = useState()
  const [password, setPassword] = useState()
  const [messageError, setMessageErrorLogin] = useState()
  const [gif, setGif] = useState(false)

  const [mailMobile, setMailMobile] = useState()
  const [passwordMobile, setPasswordMobile] = useState()

  async function logIn() {
    setGif(true)

    const response = await api.post('/login', {
      email: mail,
      password: password
    })

    if (response) {
      setGif(false)
    }

    if (response.status == 200) {
      localStorage.setItem('@TOKEN', response.data.access_token)
      setMessageErrorLogin(null)
      setMail('')
      setPassword('')
      const data = await api.get('/recipient')

      let verifyUserExistsInDatabase = data.data.expire

      if (verifyUserExistsInDatabase) {
        window.location.href = '/dashboard'
      }

      if (!verifyUserExistsInDatabase) {
        window.location.href = '/dashboard'
      }
    }

    if (response.status == 401) {
      setMessageErrorLogin(
        'Desculpe, endereço de e-mail ou senha incorretos. Por favor, tente novamente'
      )
    }
  }

  async function responseFacebook(response){

    const responseFromApi = await api.post('/login/social',{
      
      provider: "facebook",
      access_token: response.accessToken
  })


  if(responseFromApi.status == 200){

    localStorage.setItem('@TOKEN',responseFromApi.data.access_token)
    window.location.href="/dashboard"

  }

  }

  return (
    <>
      <div className="container_fluid">
        <div className="column1">
          <img src={cartaoanjo} className="icon_card" />

          <h1 className="textPrimary">Cartão Anjo</h1>
          <h2 className="textSecondary">Cuida da sua saúde</h2>
          <p className="textDescription">
            O CARTÃO ANJO CLUB é para todos. Não existem <br /> limitações,
            impedimentos de idade, carências,
            <br /> restrições a grau de parentesco, gestantes, doenças
            <br /> preexistentes ou crônicas.
          </p>
          <a href="https://cartaoanjo.com.br/" className="info" target="_blank">
            Saiba mais
          </a>

          <div className="buttons">
            <Button
              text="Cadastre-se"
              onClick={() => (window.location.href = '/register')}
            />
            <Button
              text="Parceiros"
              onClick={() => (window.location.href = 'partners')}
            />
          </div>
        </div>

        <div className="column2">
          <div className="container_inputs_logIn">
            <img src={iconCartaoAnjo} className="icon_container_inputs_login" />
            <h2>Dados de acesso</h2>
            {statusMailChange == -1 ? (
              ''
            ) : (
              <h2 style={{ fontSize: '0.9em' }}>Senha Alterada com Sucesso.</h2>
            )}
            <input
              type="email"
              placeholder="E-mail"
              onChange={e => setMail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Senha"
              onChange={e => setPassword(e.target.value)}
            />
            {messageError != null ? (
              <p className="messageError">{messageError}</p>
            ) : (
              ''
            )}

            <div className="row_inputs_login">
              <Button text="Entrar" gif={gif} onClick={logIn} />
              <a href="forgot-password">Esqueceu a senha?</a>
            </div>
          </div>
              


              <div className="arrow_container_desktop">
              <svg width="185" height="1" viewBox="0 0 185 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="185" y2="0.5" stroke="#CFCFCF"/>
              </svg>
              <p>OU</p>

              <svg width="185" height="1" viewBox="0 0 185 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="185" y2="0.5" stroke="#CFCFCF"/>
              </svg>

              </div>


              <div className="container_button_facebook_desktop">
              <FacebookLogin
              cssClass="facebook_button_desktop"
              appId="563818371558210"
              autoLoad={false}
              textButton="Entrar com o facebook"
              fields="name,email,picture"
              callback={responseFacebook} />
              <img src={facebookIcon}/>
            </div>

          <div className="footer_version">
            <h2>Cartão Anjo: v7.0 &copy;</h2>
          </div>
        </div>
      </div>

      <div className="mobile_layout">
        <div className="mobile_layout_landing_app">
          <img
            src={iconCartaoAnjo}
            className="icon_mobile_layout_landing_app"
          />
          <img
            src={cartaoanjo}
            className="card_icon_mobile_layout_landing_app"
          />

          <div className="description_mobile_layout_landing_app">
            <h2>Cartão Anjo</h2>
            <h2>Cuida da sua saúde</h2>

            <p className="description_app_mobile_layout_landing_app">
              O CARTÃO ANJO CLUB é para todos. Não <br /> existem limitações,
              impedimentos de <br /> idade, carências, restrições a grau de{' '}
              <br /> parentesco, gestantes, doenças <br /> preexistentes ou
              crônicas.
            </p>
            <p
              className="saiba_mais_mobile_layout_landing_app"
              onClick={() =>
                (window.location.href = 'https://cartaoanjo.com.br/')
              }
            >
              Saiba Mais
            </p>

            <div className="container_buttons_mobile_layout_landing_app">
              <button onClick={() => (window.location.href = '/login-mobile')}>
                Já sou cadastrado
              </button>
              <button onClick={() => (window.location.href = '/register')}>
                Cadastre-se
              </button>
              <button onClick={() => (window.location.href = '/partners')}>
                <div className="button_partner_mobile_layout_landing_app">
                  <img src={hanshakeIcon} />
                  <p className="name_button_partner_mobile_layout_landing_app">
                    Parceiros
                  </p>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LogIn
