import {create} from 'apisauce'

const api = create({

    baseURL:'https://app.cartaoanjo.com.br/api'
})

/*

 Send Token to API
 
*/


api.addAsyncRequestTransform(response => async() =>{
    
    const token = localStorage.getItem('@TOKEN')
    
    if(token){

        response.headers['Authorization']  = `Bearer ${token}`

    }

})




export default api