import React from 'react'
import './style.css'

import notFoundIcon from '../../images/notFoundicon.png'
import returnIcon from '../../images/returnIcon.png'

const NotFound = () =>{

    return(

        <div className="container_not_found_page">

        <h1>Oops!</h1>
        <h2>Pagina não encontrada</h2>
        <img src={notFoundIcon} className="icon_not_found"/>
        <img src={returnIcon} className="return_icon" onClick={() => window.location.href="/"}/>
        </div>


    )

}

export default NotFound