import React,{useState,useEffect} from 'react'
import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'

import BottomBarMobile from '../../components/BottomBarMobile'

import PlansMobileComponent from '../../components/PlansMobileComponent'
import api from '../../services/api'


import './style.css'

const PlansMobile = () =>{

    const [data,setData] = useState([])

    async function getPlans(){
        const response = await api.get('/plans')
        if(response.status != 200){
            return false
        }
        
        setData(response.data)
    }

    useEffect(() =>{

        getPlans()

    },[])


    return(

        <>
        <HeaderDashboardMobile icon={false} page="login" />
        
        <div className="plans_mobile_container">

        <h2>Planos</h2>


        {data.map(item => {
        
        return  <PlansMobileComponent value={item.value} type={item.type} id={item.id}/>

        })}



        </div>

        
        </>


    )

}

export default PlansMobile