import React, { useState,useEffect } from 'react'

import userIcon from '../../images/userProfile.png'

import api from '../../services/api'


import './style.css'

import verifyUser from '../../functions/verifyUser'

const Header = ({namePage,page}) =>{


    const [expires,setExpire] = useState()
    const [image,setImage] = useState()
    const [name,setName] = useState()

    const [token,setToken] = useState()
    const [imageLocal,setImageLocal] = useState()
    const [nameLocal,setNameLocal] = useState()

    const [imageStateLocal,setImageStateLocal] = useState()
    const [responseVerifyUser,setResponseVerifyUser] = useState()

    async function getDataUser(){

        const response = await  api.get('/recipient')

        setExpire(response.data.expire)
        setImage(response.data.user.imagemBase64)
        setName(response.data.user.name)
        verifyUser(response.data.message)
        
        console.log(response.data.message)

        
        localStorage.setItem('@PICTURE_USER',response.data.user.imagemBase64)

        localStorage.setItem('@NAME_USER',response.data.user.name)
    


    
    }


    const getDataLocal = () =>{

        const pictureUser =  localStorage.getItem('@PICTURE_USER')
        const nameUser = localStorage.getItem('@NAME_USER')

        setImageLocal(pictureUser)


        if(!pictureUser){
            setImageStateLocal(false)
            return false 
        }

        if(pictureUser.length  == 4){

            setImageStateLocal(false)

        }

        if(pictureUser.length > 4){

            setImageStateLocal(true)

        }

        setNameLocal(nameUser)
    }
  




    async function getToken(){

        const token = localStorage.getItem('@TOKEN')
        setToken(token)
        if(page != 'partners'){


        if(!token){

            window.location.href="/"

        }
        }




    }



    useEffect(() =>{
    getDataUser()
    getToken()
    },[])

    useEffect(() =>{

      getDataLocal()


    },)


    const exitApp = () =>{

        localStorage.removeItem('@TOKEN')
        localStorage.removeItem('@expireFormatedVerify')

        localStorage.removeItem('@name')
        localStorage.removeItem('@referralLink')
        localStorage.removeItem('@balance')
        localStorage.removeItem('@quantityIndication')
  

        window.location.href="/"
    }


    return(
        
        <div className="header">
        
        <h1>{namePage}</h1>


        {token == undefined ? '' 
        
        :
        
        <>


        <div class="dropdown">

        
  
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {imageStateLocal == false  ? <img src={userIcon} className="icon_partner_dropdown"/> :<img src={`data:image/;base64,${imageLocal}`} className="icon_partner_dropdown"/>}
        <p style={{marginLeft:'5px',marginRight:'15px'}}>{nameLocal}</p>
 
        </button>
     
    
  
  

        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" href="profile" >Perfil</a>
        <a class="dropdown-item" href="" onClick={() => exitApp() }>Sair</a>
         
        </div>
        </div>


        
        </>
        
        }


        </div>


    )


}

export default Header
