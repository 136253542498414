import React,{useState,useEffect} from 'react'

import walletIcon from '../../images/hanshakeIcon.png'

import './style.css'
import moment from "moment";

import InputMaskedMobile from '../../components/InputMaskedMobile'

const IndicationsMobileComponent = ({name,plan,comission,state,type}) => {
    

    return(

        <div className="historic_transactions_mobile_component">

            <div className="row_historic_transactions_mobile_component">

            <img src={walletIcon}/>

            <div className="data_historic_transactions_mobile_component">

            <p>Beneficiario</p>
            <p>{name}</p>
            </div>


           <div className="data_historic_transactions_mobile_component">

            <p>Plano</p>
            <p>{type == 'P' ? 'Anual' : 'Mensal'}</p>
            </div>


            <div className="data_historic_transactions_mobile_component">

            <p>Comissão</p>
            <p>{comission.toLocaleString('pt-br',{style:'currency',currency:'BRL'})}</p>
            </div>

            <div className="data_historic_transactions_mobile_component">

            <p>Situação</p>
            <p>{state == 'A' ? 'Ativo' : 'Desativo'}</p>
            </div>


            </div>

        </div>

    )


}

export default IndicationsMobileComponent