import React,{useState,useEffect} from 'react'

import './style.css'

import punhosIcon from '../../images/punhosIcon2.png'
import setaIcon from '../../images/setaIcon.png'
import porcoIcon from '../../images/porcoIcon.png'

import copyIcon from '../../images/copyIcon.png'
import shareIcon from '../../images/shareIcon.png'
import Api from '../../services/api'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const DashboardsMobile = () =>{


    const [quantityIndication,setQuantityIndication] = useState()
    const [balance,setBalance] = useState()
    const [referral,setReferralLink] = useState()

    async function getDataUser(){

        const data = await Api.get('/recipient')

        if(data.status == 200){           
        setQuantityIndication(data.data.quantityIndication)
        setBalance(data.data.balance)
        setReferralLink(data.data.referral)
        }else{

            setQuantityIndication("")
            setBalance("")
            setReferralLink("")
        }


    }


    useEffect(() =>{

        getDataUser()

    },[])


    return(

        <div className="dashboard_mobile">

        <div className="flex_text_dashboard_mobile">

        <h2>Indicações</h2>
        
        
        </div>
        <div className="row_dashboard_mobile">

        <div>
        

        <p>N° de indicações</p>

        <div className="flex_row_dashboard_indications_mobile" onClick={() => window.location.href="/indications-mobile"}>

        <img src={punhosIcon} className="punhos_icon_indications_mobile"/>
        <p>N° {quantityIndication != null ? quantityIndication : '0'}</p>
        <img src={setaIcon} className="arrow_img_indications_mobile"/>

        </div>

        </div>

     
        <div>
        
        <p>Saldo</p>

        <div className="flex_row_dashboard_indications_mobile">

        <img src={porcoIcon} className="porco_icon_indications_mobile"/>
        <p>R$ {balance != null ? balance.toLocaleString('pt-br',{style:'currency',currency:'BRL'}) : '0,00'}</p>
        <img src={setaIcon} className="arrow_img_value_mobile"/>

        </div>

        </div>

        </div>


        <div className="link_indications_dashboards_mobile">

        <p>Compartilhe seu link de indicação</p>

        <div className="row_container_link_indications_mobile">
        
        {referral == null ? '' 
        
        :

        <div className="flex_row_dashboard_indications_mobile">

        <CopyToClipboard text={`https://central.cartaoanjo.com.br/register/${referral}`}>
        <button className="copy_link_dashboard_indications_mobile" onClick={() => window.alert('Dados copiados com sucesso!')}>
        <img src={copyIcon} className="porco_icon_indications_mobile"/>
        <p>Copiar link</p>
        </button>

        </CopyToClipboard>

        </div>

        }


        {referral == null ? '' 
        
        :

        <div className="flex_row_dashboard_indications_mobile" >

        <CopyToClipboard text={`https://central.cartaoanjo.com.br/register/${referral}`}>

        <button className="share_dashboard_indications_mobile" onClick={() => window.alert('Dados copiados com sucesso!')}>
        <img src={shareIcon} className="porco_icon_indications_mobile"/>
        <p>Compartilhar</p>
        </button>
        </CopyToClipboard>

        </div>
        
        }



        </div>




     

        </div>

        </div>


    )

}

export default DashboardsMobile