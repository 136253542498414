import InputMask from 'react-input-mask';

import './style.css'

const InputMaskedMobile = ({mask,placeholder,onChange,value,type,width,left,error,onFocus,onBlur}) =>{        
    
    return(
        <InputMask type={type}  maskChar={null} className="input_register_mobile" mask={mask} placeholder={placeholder} Autofill={false} onChange={onChange} onBlur={onBlur} onFocus={onFocus} value={value} style={{width:width,position:'relative',left:left}} />
    )
}

export default InputMaskedMobile