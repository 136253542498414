
export default async function verifyUser(response){

    if(response == 'The token could not be parsed from the request' || response == 'Token is expired'){

        localStorage.removeItem('@TOKEN')
        localStorage.removeItem('@expireFormatedVerify')

        localStorage.removeItem('@name')
        localStorage.removeItem('@referralLink')
        localStorage.removeItem('@balance')
        localStorage.removeItem('@quantityIndication')
  
        window.location.href='/login'

        }


}