import React from 'react'
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';

const Map = ({lat,long}) =>{

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD7AA9nnKYrUc4pprxQf53QJf_t5RDLxDE"
      })


      const center = {

        lat: Number(lat),
        lng: Number(long)

      }
      return isLoaded ? (
        <GoogleMap
          mapContainerStyle={{width:'230px',height:'230px',borderRadius:'20px'}}
          center={center}
          zoom={10}
        >
        <Marker position={center} />

          <></>
        </GoogleMap>
    ) : <></>


}

export default Map