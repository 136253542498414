import React,{useState,useEffect} from 'react'
import './style.css'

import InputMobile from '../../components/InputMobile'
import InputMaskedMobile from '../../components/InputMaskedMobile'
import ButtonMobile from '../../components/ButtonMobile'

import HeaderMobile from '../../components/HeaderMobile'
import CheckboxMobile from '../../components/CheckboxMobile'

import SelectMobile from '../../components/SelectMobile'
import api from '../../services/api'

const RegisterMobile = () =>{


    const [day,setDay] = useState()
    const [month,setMonth] = useState()
    const [year,setYear] = useState()

    const [sex,setSex] = useState()
    const [terms,setTerms] = useState(false) 

    const [dayError,setDayError] = useState()
    const [yearError,setYearError] = useState()

    const [monthError,setMontError] = useState()

    const [sexError,setSexError] = useState()
    const [termsError,setTermsError] = useState()

    const [dataApi,setDataApi] = useState()

    const [gif,setGif] = useState(false)

    const [typeMailing,setTypeMailing] = useState() 

    const [idFromLocalStorage,setIdfromLocalStorage] = useState()


    const mail = localStorage.getItem('@mail')
    const cpf = localStorage.getItem('@cpf')
    const name =  localStorage.getItem('@name')
    const number = localStorage.getItem('@number')
    const password2 = localStorage.getItem('@password')




    const cep = localStorage.getItem('@cep')
    const address = localStorage.getItem('@address')
    const neighboard = localStorage.getItem('@neighboard')
    const complement = localStorage.getItem('@complement')
    const city_id = localStorage.getItem('@city_id')
    const id = localStorage.getItem('@id')

    

    async function setData(){


        if(year == null){

            setYearError('Informe um ano')

        }


        if(day == null){

            setDayError('Informe um dia')

        }

        if(month == null){

            setMontError('Informe um mês')

        }

        if(sex == null){

            setSexError('Informe seu sexo')

        }

        if(terms == false){

            setTermsError('Necessário concordar os termos!')
            
        }



        if(day == null || month == null || year == null || sex == null){
            
            return false
        }


        setGif(true)
        



        if(id.length != 9){


        const response = await api.post('/recipient',{
            "name":name,
            "email":mail,
            "password":password2,
            "cpf":cpf,
            "gender":sex,
            "mailing":'N',
            "birth": `${day}/${month}/${year}`,
            "referrer":id,
            "phones": [
                {
                  "type":'M',
                  "number": number
                }
              ],

              address: [
                {
                  city_id:city_id,
                  type: "R",
                  address: address,
                  complement:complement,
                  zip:cep,
                  neighborhood: neighboard,
           
                }
              ],
      


        })


        if(response.status == 200){
            window.location.href="/sucess-page-mobile"
            setGif(false)

        }

        if(response.status != 200){

            
            if(response.data.errors.cpf != undefined){

                alert(response.data.errors.cpf)


            }

            if(response.data.errors.email != undefined){

                alert(response.data.errors.email)


            }


            setGif(false)

        }        


        }



        if(id.length == 9){

        const response = await api.post('/recipient',{
            "name":name,
            "email":mail,
            "password":password2,
            "cpf":cpf,
            "gender":sex,
            "mailing":'N',
            "birth": `${day}/${month}/${year}`,
            "phones": [
                {
                  "type":'M',
                  "number": number
                }
              ],

              address: [
                {
                  city_id:city_id,
                  type: "R",
                  address: address,
                  complement:complement,
                  zip:cep,
                  neighborhood: neighboard,
           
                }
              ],
      


        })


        if(response.status == 200){
            window.location.href="/sucess-page-mobile"
            setGif(false)

        }

        if(response.status != 200){
            alert('Opss.. algo deu errado :(')
            setGif(false)

        }        


        }



    }

    useEffect(() =>{

        if(day){

            if(day.length > 0){

                setDayError('')

            }

        }

        if(month){

            if(month.length > 0){

                setMontError('')

            }

        }

        if(year){

            if(year.length > 0){

                setYearError('')

            }

        }

        if(sex){

            if(sex != ""){

                setSexError('')

            }

        }

        if(termsError){

            if(termsError != false){

                setTermsError('')

            }

        }

    })


    return(
        <>
        <HeaderMobile page="register"/>
        <div className="register_mobile">

        <div className="label_mobile">
        <p>Data de Nascimento</p>
        </div>


        <div className="flex_inputs_register_mobile_step2">

        <div style={{width:'100%'}}>
        <InputMaskedMobile placeholder="Dia" mask="99" type="tel" width="90%" left="-5px"  onChange={(e) => setDay(e.target.value)}/>
        {dayError != null ? <p className="message_error_mobile_step2">{dayError}</p> : ''}
        </div>

        <div style={{width:'100%'}}>
        <InputMaskedMobile placeholder="Mês" mask="99" type="tel" width="90%" left="-5px" onChange={(e) => setMonth(e.target.value)}/>
        {monthError != null ? <p className="message_error_mobile_step2">{monthError}</p> : ''}

        </div>

        <div style={{width:'100%'}}>
        <InputMaskedMobile placeholder="Ano" mask="9999" type="tel" width="90%" left="-5px" onChange={(e) => setYear(e.target.value)}/>
        {yearError != null ? <p className="message_error_mobile_step2"> {yearError}</p> : ''}

        </div>

        </div>

        <div className="label_mobile">
        <p>Gênero</p>
        </div>

        <div>
        <SelectMobile onChange={(e) => setSex(e.target.value)}/>
        {sexError != null ? <p className="message_error_mobile_step2_sex"> {sexError}</p> : ''}
        </div>

        <div className="terms_and_conditions_mobile">

        <h2>Termos e condições</h2>

        
        <CheckboxMobile text="Desejo receber novidades e ofertas especiais da cartão anjo." id='1'/>
        
        <div>
        <CheckboxMobile text="Ao clicar neste botão, eu concordo com os termos de uso da cartão anjo" id='2' onChange={(e) => setTerms(!terms)}/>
        {termsError != null ? <p className="message_error_mobile_step2"> {termsError}</p> : ''}

        </div>

        </div>


        <div className="button_register_mobile">
        <ButtonMobile text="Concluir Cadastro" onClick={setData} gif={gif}/>
        </div>

        </div>
        </>

    )

}

export default RegisterMobile