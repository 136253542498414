import React from 'react'
import './style.css'

import gifIcon from '../../images/loader.gif'

const Button = ({text,onClick,gif,disabled}) =>{


    return(

        <button className="button" onClick={onClick} disabled={disabled}>
        {gif == true ? <img src={gifIcon} style={{width:'40px'}}/> :text}
        </button>

    )


}

export default Button