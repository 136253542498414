import React,{useState} from 'react'
import HeaderMobile from '../../components/HeaderMobile'
import backgroundImage from '../../images/backgroundIcon.png'
import './style.css'
import InputMobile from '../../components/InputMobile'
import ButtonMobile from '../../components/ButtonMobile'

const ForgotPassword = () =>{

    const [mail,setMail] = useState() 
    const [gif,setGif] = useState(false)

    const [mailError,setMailError] = useState()

    const recoverPassword = () =>{

        if(mail == null){

            setMailError('Informe um email')
            return false
            
        }


        setGif(true)

        localStorage.setItem('@mail_recover',mail)
        window.location.href="/sucess-send-mail-mobile"
        

    }

    return(

        <>
        <HeaderMobile page="login-mobile"/>

        <div className="container_forgot_password_mobile">

        <h2 className="text_primary_forgot_password">Esqueceu a senha?</h2>

        <p>Não se preocupe! Digite seu e-<br/>mail para receber instruções de <br/> recuperação de senha</p>

        <div className="container_image_forgot_password_mobile">
        <img src={backgroundImage}/>
        </div>

        <div className="container_input_forgot_password_mobile">


        <div className="input_forgot_password_mobile">
        <InputMobile placeholder="E-mail" onChange={(e) => setMail(e.target.value)} />
        {mailError != null ? <p className="message_error_forgot_password_mobile">{mailError}</p> : ''}

        </div>

        <div className="row_forgot_password_mobile">

        <p className="remember_forgot_password_mobile">Lembrou da senha?</p>
        <p className="login_forgot_password_mobile" onClick={() => window.location.href="/login-mobile"}>Entrar</p>
        </div>
        
        <div className="container_button_forgot_password_mobile">
        <ButtonMobile text="Recuperar Senha" onClick={recoverPassword} gif={gif}/>

        <p className="remember_forgot_password_mobile">Não tem uma conta?</p>
        <p className="login_forgot_password_mobile" onClick={() => window.location.href="/register"}>Cadastre-se</p>

        </div>

        </div>


        </div>


        </>


    )

}

export default ForgotPassword