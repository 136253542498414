import React,{useState,useEffect} from 'react'
import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'


import card2Icon from '../../images/card3Icon.png'
import pixIcon from '../../images/pixIcon2.png'
import copyIcon from '../../images/copyIcon.png'

import uploadIcon from '../../images/uploadIcon.jpeg'

import './style.css'

import InputMaskedMobile from '../../components/InputMaskedMobile'
import SelectMobile from '../../components/SelectMobile'
import ButtonMobile from '../../components/ButtonMobile'

import api from '../../services/api'


const PaymentPixMobile = () =>{

    const [comprovante,setComprovante] = useState()
    const [comprovanteError,setComprovanteError] = useState()
    const [base64Comprovante,setBase64Comprovante] = useState()

    const [memberId,setMemberId] = useState()
    const [gif,setGif] = useState(false)


    async function getInfoUser(){

        const response = await api.get('/recipient')
        setMemberId(response.data.id)

        console.log(response)

    }


    async function setPaymentPix(){

   
        if(comprovante == null){

            setComprovanteError('Informe um comprovante!')
            return false
        }

        const plan = localStorage.getItem('@plan_mobile')

        setGif(true)

        
        setTimeout(() =>{

            setGif(false)

        },2000)


        let response = await api.post('/payment/others',{

            member: memberId,
            type: "P",
            plan: plan,
            descriptor: "pagamento",
            receipt: base64Comprovante

        })


        if(response){

            setGif(false)
        }



        if(response.status == 200){

        alert('pagameto realizado com sucesso! entraremos em contato em breve :)')
        window.location.href="/dashboard"
        }

        if(response.status != 200){

            alert('opss. algo deu errado!')

        }


    }



    useEffect(() =>{

        getInfoUser()


    },[])


    useEffect(() =>{
        


        if(comprovante != null){

            setComprovanteError('')

        }

        if(comprovante){

            var reader = new FileReader()

            reader.readAsDataURL(comprovante)
    
            reader.onload = () =>{
    
                let result = reader.result
                
                setBase64Comprovante(result)
                
            }
    

        }
    })


    return(

        <>
        <HeaderDashboardMobile icon={false} page="payment-card-mobile" />
        
        <div className="payment_card_mobile">

        <div className="select_type_payment_card_mobile">

        <div className="card_option_payment_pix_mobile" onClick={() => window.location.href="/payment-card-mobile"}>
        <img src={card2Icon}/>
        <p>Cartão</p>
        </div>

        <div className="pix_option_payment_pix_mobile">
        <img src={pixIcon}/>
        </div>
        </div>

        <div className="input_container_payment_card_mobile">

     

        <div>
        <label>Chave PIX</label>
        <InputMaskedMobile value="CNPJ: 34.410.302/0001-50"/>
        <img src={copyIcon}/>
        </div>

        {comprovante == null 
        
        ?
    
        <div className="container_upload_payment_card_mobile">
        <label htmlFor='file'><img  src={uploadIcon} className="upload_payment_card_mobile" /></label>

        <input type="file" id="file"  accept="image/png,image/jpeg" onChange={(e) => setComprovante(e.target.files[0])}/>
        </div>

        :

        
        <div className="container_comprovante_payment_card_mobile">
        <p>{comprovante.name}</p>
        </div>
        }
       

        <div className="payment_card_mobile_button">
        <ButtonMobile text="Assinar plano" gif={gif} onClick={setPaymentPix} />
        </div>

        </div>

     
        </div>

        
        </>


    )

}

export default PaymentPixMobile