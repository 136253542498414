import React,{useState,useEffect} from 'react'

import Button from '../../components/Button'
import Input from '../../components/Input'
import InputMasked from '../../components/InputMasked'
import Select from '../../components/Select'

import './style.css'
import iconCartaoAnjo from '../../images/iconCartaoAnjo.png'
import cartaoanjo from '../../images/cartaoAnjo.png'

import api from '../../services/api'

import {useParams} from 'react-router-dom'


import InputMobile from '../../components/InputMobile'
import InputMaskedMobile from '../../components/InputMaskedMobile'
import ButtonMobile from '../../components/ButtonMobile'

import HeaderMobile from '../../components/HeaderMobile'


const Landing = () =>{
    
    const {id} = useParams()

    


    const [mail,setMail] = useState()
    const [password,setPassword] = useState()
    const [password2,setPassword2] = useState()

    const [cpf,setCpf] = useState()
    const [name,setName] = useState()
    const [day,setDay] = useState()

    const [month,setMonth] = useState()
    const [year,setYear] = useState()
    const [number,setNumber] = useState()

    const [checkTerms,setCheckTerms] = useState(false)
    const [sex,setSex] = useState()
    const [mailReceive,setMailReceive] = useState(false)

    /*
    
    Error Messages

    */

    const [mailError,setEmailError] = useState()
    const [passwordError,setPasswordError] = useState()
    const [passwordError2,setPasswordError2] = useState()

    const [cpfError,setCpfError] = useState()
    const [nameError,setNameError] = useState()
    const [dayError,setDayError] = useState()

    const [monthError,setMonthError] = useState()
    const [yearError,setYearError] = useState()
    const [numberError,setNumberError] = useState()

    const [checkTermsError,setCheckTermsError] = useState()
    const [sexErros,setSexErros] = useState()
    const [gif,setGif] = useState(false)


    const [cepError,setCepError] = useState()
    const [addressError,setAddressError] = useState()
    const [neighboardError,setNeighboardError] = useState()

    const [complementError,setComplementError] = useState()
    const [stateSelectError,setStateSelectError] = useState()




    /* Address */

    const [citys,setCitys] = useState()
    const [citysArray0,setCitysArray0] = useState([])

    const [showSelect,setShowSelect] = useState(false)
    const [searchSelect,setSearchSelect] = useState()

    const [cep,setCep] = useState()
    const [address,setAddress] = useState()
    const [neighboard,setNeighboard] = useState()

    const [complement,setComplement] = useState()
    const [citySelectUser,setCitySelectUser] = useState([])
    const [nameRefferrerFromApi,setNameReferrerFromApi] = useState()

    const [citySelectUserError,setCitySelectUserError] = useState()
    const [cityNotSelect,setCityNotSelect] = useState([])
    const [request,setRequest] = useState()



    /* Via Cep Data */

    const [cityFromViaCep,setCityFromViaCep] = useState() 
    const [stateFromViaCep,setStateFromViaCep] = useState()
    const [showCityFromApi,setShowCityFromApi] = useState()


    async function setData(){


        if(mail == undefined){

            setEmailError('Informe um e-mail')

        }

        if(password == undefined){

            setPasswordError('Informe uma senha')

        }

        if(password != password2){

            setPasswordError2('As senhas devem ser iguais')

        }

        if(cpf == undefined){

            setCpfError('Informe um CPF')


        }

        if(name == undefined){

            setNameError('Informe um nome')

        }

        if(day == undefined){

            setDayError('Informe um dia')

        }

        if(month == undefined){

            setMonthError('Informe um mês')

        }

        if(year == undefined){

            setYearError('Informe um ano')
        }

        if(number == undefined){

            setNumberError('Informe um número de telefone')
        }

        if(checkTerms == false){

            setCheckTermsError('Necessário concordar com os termos!')

        }

        if(sex == undefined){

            setSexErros('Informe seu sexo')

        }

        /* Address Validate */

        if(cep == undefined){

            setCepError('Informe um CEP')

        }

        if(address == undefined){

            setAddressError('Informe um endereço')

        }

        if(neighboard == undefined){

            setNeighboardError('Informe um bairro')

        }

        if(complement == undefined){

            setComplementError('Informe um complemento')

        }

        /* Select Validate  */

        if(searchSelect == undefined){

            setStateSelectError('Informe um estado')

        }


        if(citySelectUser.length == 0 ){

            setCitySelectUserError('Informe um cidade')

        }

        
        

        if(mail == undefined || password == undefined || password != password2 || cpf == undefined || name == undefined || day == undefined || month == undefined || year == undefined || number == undefined || checkTerms == false ||sex == undefined    )
        {
            return false 
        }


        let token = localStorage.getItem('@TOKEN')

        if(token){

            localStorage.removeItem('@TOKEN')

        }

        setGif(true)
  

        if(id != undefined){


        const response = await api.post('/recipient',{
            "name":name,
            "email":mail,
            "password":password2,
            "cpf":cpf,
            "gender":sex,
            "mailing":mailReceive == false ? 'N' : 'Y' ,
            "birth": `${day}/${month}/${year}`,
            "referrer": id,

            "phones": [
                {
                  "type":'M',
                  "number": number
                }
              ],

              address: [
                {
                  city_id:citySelectUser.length == 0 ? cityNotSelect :citySelectUser ,
                  type: "R",
                  address: address,
                  complement:complement,
                  zip:cep,
                  neighborhood: neighboard,
           
                }
              ],
      


        })




        if(response){

            setGif(false)

        }


        if(response.status == 200){
            localStorage.removeItem('@PICTURE_USER')
            localStorage.removeItem('@NAME_USER')
            window.location.href="/sucess-register"

        }

        if(response.status != 200){


            if(response.data.errors.cpf != undefined){

                alert(response.data.errors.cpf)


            }

            if(response.data.errors.email != undefined){

                alert(response.data.errors.email)


            }


        }



        }

        if(id == undefined){


                const response = await api.post('/recipient',{
                    "name":name,
                    "email":mail,
                    "password":password2,
                    "cpf":cpf,
                    "gender":sex,
                    "mailing":mailReceive == false ? 'N' : 'Y' ,
                    "birth": `${day}/${month}/${year}`,
        
                    "phones": [
                        {
                          "type":'M',
                          "number": number
                        }
                      ],
        
                      address: [
                        {
                          city_id:citySelectUser.length == 0 ? cityNotSelect :citySelectUser,
                          type: "R",
                          address: address,
                          complement:complement,
                          zip:cep,
                          neighborhood: neighboard,
                   
                        }
                      ],
              
        
        
                })
        
        
        
        
                if(response){
        
                    setGif(false)
        
                }
        
        
                if(response.status == 200){

                    localStorage.removeItem('@PICTURE_USER')
                    localStorage.removeItem('@NAME_USER')
                    window.location.href="/sucess-register"
        
                }
        
                if(response.status != 200){
        
        
                    if(response.data.errors.cpf != undefined){
        
                        alert(response.data.errors.cpf)
        
        
                    }
        
                    if(response.data.errors.email != undefined){
        
                        alert(response.data.errors.email)
        
        
                    }
        
        
                }
        
        
        



        }


    }


    const setDataMobile = () =>{


        if(mail == undefined){

            setEmailError('Informe um e-mail')

        }

        if(password == undefined){

            setPasswordError('Informe uma senha')

        }

        if(password != password2){

            setPasswordError2('As senhas devem ser iguais')

        }

        if(cpf == undefined){

            setCpfError('Informe um CPF')


        }

        if(name == undefined){

            setNameError('Informe um nome')

        }

        if(number == undefined){

            setNumberError('Informe um número de telefone')
        }



        /* Address Validate */

        if(cep == undefined){

            setCepError('Informe um CEP')

        }

        if(address == undefined){

            setAddressError('Informe um endereço')

        }

        if(neighboard == undefined){

            setNeighboardError('Informe um bairro')

        }

        if(complement == undefined){

            setComplementError('Informe um complemento')

        }

        /* Select Validate  */

        if(searchSelect == undefined){

            setStateSelectError('Informe um estado')

        }



        if(mail == undefined || password == undefined || password != password2 || cpf == undefined || name == undefined || number == undefined ){

            return false
        }

        setGif(true)


        localStorage.setItem('@mail',mail)
        localStorage.setItem('@cpf',cpf)
        localStorage.setItem('@name',name)
        localStorage.setItem('@number',number)
        localStorage.setItem('@password',password)
        localStorage.setItem('@id',id)


        localStorage.setItem('@cep',cep)
        localStorage.setItem('@address',address)
        localStorage.setItem('@neighboard',neighboard)
        localStorage.setItem('@complement',complement)



        localStorage.setItem('@city_id',citySelectUser)



        setTimeout(() =>{

        window.location.href="/register-mobile-step2"

        },2000)




    }



    useEffect(() =>{

        if(password == password2){

            setPasswordError2('')

        }

    })





  async function searchDataCitys(e){
    
    
    if(e == ''){
        return false
        }
    
    
        if(e != undefined){  
        setSearchSelect(e)
  
        console.log(`searchdatacity ==> ${e}`)
      
        const id_city = await api.get(`/state-city/${e}`)
    
        let array_citys = [] 
        array_citys.push(id_city)  
  
        setCitys(array_citys)
  
   
        array_citys.map(item => setCitysArray0(item.data))  
        setShowSelect(true)  


        }
    

  }

  


  useEffect(() =>{

    async function getNamePartnerFromApi(){

        let response = await api.get(`/referrer/${id}`)


        setNameReferrerFromApi(response.data.name)


    }
    getNamePartnerFromApi()

  },[])


  useEffect(() =>{

    if(mail){

        if(mail.length != 0){

            setEmailError('')
    
        }


    }


    
    if(password){

        if(password.length != 0){

            setPasswordError('')
    
        }


    }


    if(cpf){

        if(cpf.length > 0){

            setCpfError('')

        }

    }

    
    if(name){

        if(name.length != 0){

            setNameError('')
    
        }
        


    }

    
    if(number){

        if(number.length != 0){

            setNumberError('')
    
        }
        


    }


    if(day){

        if(day.length != 0){

            setDayError('')
    
        }
        


    }

    if(month){

        if(month.length != 0){

            setMonthError('')
    
        }
        


    }

    if(year){

        if(year.length != 0){

            setYearError('')
    
        }
        


    }

    if(checkTerms){

        if(checkTerms != false){

            setCheckTermsError('')

        }

    }

    if(sex){

        if(sex != "" ){

            setSexErros('')

        }

    }


    if(cep){

        if(cep != "" ){

            setCepError('')

        }

    }

    if(address){

        if(address != "" ){

            setAddressError('')

        }

    }

    if(complement){

        if(complement != "" ){

            setComplementError('')

        }

    }

    if(neighboard){

        if(neighboard.length > 0  ){

            setNeighboardError('')

        }

    }

    if(searchSelect){

        if(searchSelect != undefined){

            setStateSelectError('')

        }

    }

    if(citySelectUser){

        if(citySelectUser.length > 0){

            setCitySelectUserError('')

        }

    }


  })


  async function getCep(e){


    let zipCodeFromInput = e.target.value

    let zipCodeFormated = zipCodeFromInput.replace('-','')

    fetch(`https://viacep.com.br/ws/${zipCodeFormated}/json/`).then(res => res.json()).then((data) =>{

    setShowCityFromApi(true)  

    setCityFromViaCep(data.localidade)
    setStateFromViaCep(data.uf)
    setNeighboard(data.bairro)
    setComplement(data.complemento)
    setAddress(data.logradouro)

    /*This code snippet performs a query to get cities by state */
    searchDataCitys(data.uf)  /* Search city by state  */

    })

  }


  const setVerifyShowSelect = (e) =>{
  
    setShowSelect(true)
    setShowCityFromApi(false)
    setStateFromViaCep()
  }


    return(
        <>
        <div className="container_fluid">

        
            <div className="column1_landing">
            <img src={cartaoanjo}  style={{marginTop:'-50px'}} className="icon_card"/>
            
            <h1 className="textPrimary">Cartão Anjo</h1>
            <h2 className="textSecondary">Cuida da sua saúde</h2>
            <p className="textDescription">O CARTÃO ANJO CLUB é para todos. Não existem <br/> limitações, impedimentos de idade, carências,<br/> restrições a grau de parentesco, gestantes, doenças<br/> preexistentes ou crônicas.</p>
            <a href='https://cartaoanjo.com.br' className="info" target='_blank'>Saiba mais</a>
            
            <div className="buttons">
            <Button text="Já sou cadastrado" onClick={() => window.location.href="/logIn"}/>
            <Button text="Parceiros" onClick={() => window.location.href="/partners"}/>
            </div>


            </div>

            <div className="clean_right_desktop"></div>

            <div className="column2">
    
            <img src={iconCartaoAnjo} className="icon_cartaoanjo"/>

            <div className="container_inputs">
            
            <div className="data_access">

            <h2 className="textCad">Cadastro</h2>

            {id != undefined 
            
            ?
            
            <>
            <div className="indication_name_desktop">
            <p>Indicado por: {nameRefferrerFromApi}</p>
            </div>

            </>
            :
            ''
        
            }

            

            <div className="descriptionName">
            <p>Dados de acesso</p>
            </div>


            <div className="container_erros">
            <Input type="email"    onChange={(e) => setMail(e.target.value)} placeholder="E-mail"/>
            {mailError != null ? <p className="errorMessage">{mailError}</p> : ''}
            </div>

            <div className="container_erros">
            <Input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Senha"/>
            {passwordError != null ? <p className="errorMessage">{passwordError}</p> : ''}
            </div>

            <div className="container_erros">
            <Input type="password" onChange={(e) => setPassword2(e.target.value)} placeholder="Confirma senha"/>
            {passwordError2 != null ? <p className="errorMessage">{passwordError2}</p> : ''}
            </div>

            </div>

            <div className="data_access">

            <h2 className="textCad2">Cadastro</h2>

            <div className="descriptionName" style={{width:'50%'}}>
            <p>Data de Nascimento</p>
            </div>

            <div className='flex'>
            
            <div className="container_erros">
            <InputMasked mask="99" placeholder="Dia" onChange={(e) => setDay(e.target.value)}/>
            {dayError != null ? <p className="errorMessage">{dayError}</p> : ''}

            </div>

            <div className="container_erros">
            <InputMasked mask="99" placeholder="Mês"  onChange={(e) => setMonth(e.target.value)}/>
            {monthError != null ? <p className="errorMessage">{monthError}</p> : ''}
            </div>

            <div className="container_erros">
            <InputMasked mask="9999" placeholder="Ano"  onChange={(e) => setYear(e.target.value)}/>
            {yearError != null ? <p className="errorMessage">{yearError}</p> : ''}
            </div>
            </div>


            <div className="descriptionName">
            <p>Gênero</p>
            </div>

            
            <div className="container_erros">
            <select onChange={(e) => setSex(e.target.value)}>
            <option value="">Selecione</option>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>

            </select>
            {sexErros != null ? <p className="errorMessage" style={{top:'40px'}}>{sexErros}</p> : ''}

            </div>




            </div>

            </div> {/* End Container container_inputs */}


            {/* Address Container */}


            <div className="container_inputs">



            <div className="data_access">


            {id != undefined 
            
            ?
            <div style={{marginBottom:'22px'}}></div>
            :
            ''
            }

            <div className="descriptionName_people_data">
            <p>Dados Pessoais</p>
            </div>

        


            <div className="container_erros">
            <InputMasked mask="999.999.999-99"     onChange={(e) => setCpf(e.target.value)} placeholder="CPF"/>
            {cpfError != null ? <p className="errorMessage">{cpfError}</p> : ''}
            </div>

            <div className="container_erros">
            <Input type="text" onChange={(e) => setName(e.target.value)} placeholder="Nome"/>
            {nameError != null ? <p className="errorMessage">{nameError}</p> : ''}
            </div>

            <div className="container_erros">
            <InputMasked mask="(99) 99999-9999" onChange={(e) => setNumber(e.target.value)} placeholder="(99) 99999-9999"/>
            {numberError != null ? <p className="errorMessage">{numberError}</p> : ''}
            </div>


            <div className="container_erros">  </div> <div className="terms"> <p className="text_primary" style={{marginBottom:'35px'}}>Termos e condições</p> <div className="flex_checkbox"> <input type='checkbox' id="check1" name="check1" onChange={(e) => setMailReceive(!mailReceive)}/> <label className="text_checkbox" htmlFor='check1'>Desejo receber novidades e ofertas <br/> especiais da cartão anjo.</label> </div> <div className="flex_checkbox"> <input type='checkbox' id="check2" name="check2" onChange={(e) => setCheckTerms(!checkTerms)} /> <label className="text_checkbox" htmlFor='check2'>Ao clicar neste botão, eu concordo com os <br/> termos de uso da cartão anjo</label> </div> {checkTermsError != null ? <p className="error_message_checkbox" style={{color:'red'}}>{checkTermsError}</p> : ''} <div className="terms_privacity"> <a href="http://cartaoanjo.com.br/termos" target="_blank"><p>Termos de uso e</p></a> <a href="http://cartaoanjo.com.br/politicas" target="_blank"><p> Politica de privacidade</p></a> </div> </div> <div className="button_cad"> <Button text="Concluir Cadastro" gif={gif} onClick={setData}/> </div>



            </div>

            <div className="data_access">

            <h2 className="textCad2">Cadastro</h2>

            <div className="descriptionName_address">
            <p>Endereço</p>
            </div>

            <div className='flex_column_data_acess'>
            
            <div className="container_erros">
            <InputMasked mask="99999-999" placeholder="CEP" onChange={(e) => setCep(e.target.value)} onBlur={(e) => getCep(e)} />
            {cepError != null ? <p className="errorMessage">{cepError}</p> : ''}

            </div>

            <div className="container_erros">
            <InputMasked placeholder="Endereço" value={address}  onChange={(e) => setAddress(e.target.value)}/>
            {addressError != null ? <p className="errorMessage">{addressError}</p> : ''}
            </div>

            <div className="container_erros">
            <InputMasked placeholder="Complemento"  value={complement}   onChange={(e) => setComplement(e.target.value)}/>
            {complementError != null ? <p className="errorMessage">{complementError}</p> : ''}
            </div>

            <div className="container_erros">
            <InputMasked  placeholder="Bairro" value={neighboard}  onChange={(e) => setNeighboard(e.target.value)}/>
            {neighboardError != null ? <p className="errorMessage">{neighboardError}</p> : ''}
            </div>

            <div className="container_erros">
            
            <select className="select_desktop_landing" onClick={setVerifyShowSelect}  onChange={(e) => searchDataCitys(e.target.value)} >
            
           
              {stateFromViaCep != undefined 
        ?
        <option value={stateFromViaCep}>{stateFromViaCep}</option>
    
        :

        showSelect == false 
    
        ?
        <>
        <option value="">Selecione</option>
        <option value="AC">Acre</option>
        <option value="AL">Alagoas</option>
        <option value="AP">Amapá</option>
        <option value="AM">Amazonas</option>
        <option value="BA">Bahia</option>
        <option value="CE">Ceará</option>
        <option value="DF">Distrito Federal</option>
        <option value="ES">Espírito Santo</option>
        <option value="GO">Goiás</option>
        <option value="MA">Maranhão</option>
        <option value="MT">Mato Grosso</option>
        <option value="MS">Mato Grosso do Sul</option>
        <option value="MG">Minas Gerais</option>
        <option value="PA">Pará</option>
        <option value="PB">Paraíba</option>
        <option value="PR">Paraná</option>
        <option value="PE">Pernambuco</option>
        <option value="PI">Piauí</option>
        <option value="RJ">Rio de Janeiro</option>
        <option value="RN">Rio Grande do Norte</option>
        <option value="RS">Rio Grande do Sul</option>
        <option value="RO">Rondônia</option>
        <option value="RR">Roraima</option>
        <option value="SC">Santa Catarina</option>
        <option value="SP">São Paulo</option>
        <option value="SE">Sergipe</option>
        <option value="TO">Tocantins</option>
        </>
        :
        <>
        <option value="AC">Acre</option>
        <option value="AL">Alagoas</option>
        <option value="AP">Amapá</option>
        <option value="AM">Amazonas</option>
        <option value="BA">Bahia</option>
        <option value="CE">Ceará</option>
        <option value="DF">Distrito Federal</option>
        <option value="ES">Espírito Santo</option>
        <option value="GO">Goiás</option>
        <option value="MA">Maranhão</option>
        <option value="MT">Mato Grosso</option>
        <option value="MS">Mato Grosso do Sul</option>
        <option value="MG">Minas Gerais</option>
        <option value="PA">Pará</option>
        <option value="PB">Paraíba</option>
        <option value="PR">Paraná</option>
        <option value="PE">Pernambuco</option>
        <option value="PI">Piauí</option>
        <option value="RJ">Rio de Janeiro</option>
        <option value="RN">Rio Grande do Norte</option>
        <option value="RS">Rio Grande do Sul</option>
        <option value="RO">Rondônia</option>
        <option value="RR">Roraima</option>
        <option value="SC">Santa Catarina</option>
        <option value="SP">São Paulo</option>
        <option value="SE">Sergipe</option>
        <option value="TO">Tocantins</option>
    
        </>
        
    
        }


            </select>
            {stateSelectError != null ? <p className="errorMessage">{stateSelectError}</p> : ''}
            </div>

            
            <div className="container_erros">
            
            <select className="select_desktop_landing"  onClick={setVerifyShowSelect}  onChange={(e) => setCitySelectUser(e.target.value)} >

            {showCityFromApi == true 
            
            ?
            <>
            <option  value={cityFromViaCep}>{cityFromViaCep}</option>
            </>

            :

            showSelect == false 
                
                ?  

                <option  value="">Cidade</option>

                :


                <>
                <option  value="">Selecione</option>

                {
                citysArray0.map(item => {

                return <option value={item.id}>{item.city}</option>

                })
            }
                
                </>

                }


            </select>
            {citySelectUserError != undefined ? <p className="errorMessage">{citySelectUserError}</p> : ''}

            </div>



            </div>

            </div>




            </div> {/* End Container container_inputs */}





            </div>

        </div>


        <HeaderMobile page="login" />

        <div className="register_mobile">

        {id != undefined 
            
            ?
            
            <>
            <div className="indication_name_desktop">
            <p>Indicado por: {nameRefferrerFromApi}</p>
            </div>
            <div style={{marginBottom:'20px'}}></div>
            </>
            :
            ''
        
            }
        <div className="label_mobile">
        <p>Dados de acesso</p>
        </div>

        <InputMobile placeholder="E-mail" type="email"  onChange={(e) => setMail(e.target.value)}  width="90%" />
        {mailError != null ? <p className="errorMessageMobile">{mailError}</p> : ''}

        <InputMobile placeholder="Senha" type="password"  onChange={(e) => setPassword(e.target.value)}  width="90%"/>
        {passwordError != null ? <p className="errorMessageMobile">{passwordError}</p> : ''}

        <InputMobile placeholder="Confirma senha" type="password"  onChange={(e) => setPassword2(e.target.value)}  width="90%"/>
        {passwordError2 != null ? <p className="errorMessageMobile">{passwordError2}</p> : ''}

        <div className="label_mobile">
        <p>Dados Pessoais</p>
        </div>

        <InputMaskedMobile placeholder="CPF" mask="999.999.999-99"  onChange={(e) => setCpf(e.target.value)} type="tel" width="90%"/>
        {cpfError != null ? <p className="errorMessageMobile">{cpfError}</p> : ''}
        
        <InputMobile placeholder="Nome" type="text"   onChange={(e) => setName(e.target.value)}  width="90%" />
        {nameError != null ? <p className="errorMessageMobile">{nameError}</p> : ''}
        
        <InputMaskedMobile placeholder="(99) 99999-9999" mask="(99) 99999-9999" type="tel"  onChange={(e) => setNumber(e.target.value)}  width="90%"/>
        {numberError != null ? <p className="errorMessageMobile">{numberError}</p> : ''}


        <InputMaskedMobile placeholder="CEP" onBlur={(e) => getCep(e)} mask="99999-999" type="tel"  onChange={(e) => setCep(e.target.value)}  width="90%"/>
        {cepError != null ? <p className="errorMessageMobile">{cepError}</p> : ''}

        
        <InputMobile placeholder="Endereço" type="text" value={address}  onChange={(e) => setAddress(e.target.value)}  width="90%" />
        {addressError != null ? <p className="errorMessageMobile">{addressError}</p> : ''}

         
        <InputMobile placeholder="Complemento" type="text" value={complement}  onChange={(e) => setComplement(e.target.value)}  width="90%" />
        {complementError != null ? <p className="errorMessageMobile">{complementError}</p> : ''}

        
        <InputMobile placeholder="Bairro" type="text" value={neighboard}   onChange={(e) => setNeighboard(e.target.value)}  width="90%" />
        {neighboardError != null ? <p className="errorMessageMobile">{neighboardError}</p> : ''}

            <div className="select_mobile">
            <select onClick={setVerifyShowSelect}  onChange={(e) => searchDataCitys(e.target.value)} >
            {stateFromViaCep != undefined 
        ?
        <>
        <option value="">Selecione</option>
        <option value={stateFromViaCep}>{stateFromViaCep}</option>
        </>
        :

        showSelect == false 
    
        ?
        <>
        <option value="">Selecione</option>
        <option value="AC">Acre</option>
        <option value="AL">Alagoas</option>
        <option value="AP">Amapá</option>
        <option value="AM">Amazonas</option>
        <option value="BA">Bahia</option>
        <option value="CE">Ceará</option>
        <option value="DF">Distrito Federal</option>
        <option value="ES">Espírito Santo</option>
        <option value="GO">Goiás</option>
        <option value="MA">Maranhão</option>
        <option value="MT">Mato Grosso</option>
        <option value="MS">Mato Grosso do Sul</option>
        <option value="MG">Minas Gerais</option>
        <option value="PA">Pará</option>
        <option value="PB">Paraíba</option>
        <option value="PR">Paraná</option>
        <option value="PE">Pernambuco</option>
        <option value="PI">Piauí</option>
        <option value="RJ">Rio de Janeiro</option>
        <option value="RN">Rio Grande do Norte</option>
        <option value="RS">Rio Grande do Sul</option>
        <option value="RO">Rondônia</option>
        <option value="RR">Roraima</option>
        <option value="SC">Santa Catarina</option>
        <option value="SP">São Paulo</option>
        <option value="SE">Sergipe</option>
        <option value="TO">Tocantins</option>
        </>
        :
        <>
        
        <option value="AC">Acre</option>
        <option value="AL">Alagoas</option>
        <option value="AP">Amapá</option>
        <option value="AM">Amazonas</option>
        <option value="BA">Bahia</option>
        <option value="CE">Ceará</option>
        <option value="DF">Distrito Federal</option>
        <option value="ES">Espírito Santo</option>
        <option value="GO">Goiás</option>
        <option value="MA">Maranhão</option>
        <option value="MT">Mato Grosso</option>
        <option value="MS">Mato Grosso do Sul</option>
        <option value="MG">Minas Gerais</option>
        <option value="PA">Pará</option>
        <option value="PB">Paraíba</option>
        <option value="PR">Paraná</option>
        <option value="PE">Pernambuco</option>
        <option value="PI">Piauí</option>
        <option value="RJ">Rio de Janeiro</option>
        <option value="RN">Rio Grande do Norte</option>
        <option value="RS">Rio Grande do Sul</option>
        <option value="RO">Rondônia</option>
        <option value="RR">Roraima</option>
        <option value="SC">Santa Catarina</option>
        <option value="SP">São Paulo</option>
        <option value="SE">Sergipe</option>
        <option value="TO">Tocantins</option>
    
        </>
        
    
        }

            </select>
            {stateSelectError != null ? <p className="errorMessageMobile">{stateSelectError}</p> : ''}

            </div>

            <div className="select_mobile">
            <select onClick={setVerifyShowSelect}  onChange={(e) => setCitySelectUser(e.target.value)} >

            {showCityFromApi == true 
            
            ?
            <option  value={cityFromViaCep}>{cityFromViaCep}</option>

            :

            showSelect == false 
                
                ?  

                <option  value="">Cidade</option>

                :


                <>
                <option  value="">Selecione</option>

                {
                citysArray0.map(item => {

                return <option value={item.id}>{item.city}</option>

                })
            }
                
                </>

                }


            </select>
            {citySelectUserError != undefined ? <p className="errorMessageMobile">{citySelectUserError}</p> : ''}

            </div>

        <div className="button_register_mobile">
        <ButtonMobile text="Continuar" onClick={setDataMobile} gif={gif} />
        </div>

        </div>




        </>



    )


}

export default Landing