import React, { useEffect } from 'react'

import './style.css'

import iconPartner from '../../images/iconPartner.png'
import { Carousel } from 'react-responsive-carousel'

import './map.css'

import locationIcon from '../../images/locationIcon.png'
import globalIcon from '../../images/globalIcon.png'
import mailIcon from '../../images/mailIcon.png'
import servicesIcon from '../../images/servicesIcon.png'
import phoneIcon from '../../images/phoneIcon2.png'

import Map from '../Map'

const Card = ({
  image,
  addresses,
  email,
  site,
  name,
  servicePartner,
  contacts
}) => {
  return (
    <div className="card">
      <div className="card_icon_partner">
        <div className="card_data">
          <img src={`data:image/;base64,${image}`} className="icon_partner" />
          <div className="line"></div>
          <h2>{name}</h2>
        </div>
      </div>

      <div className="address_partner">
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          showArrows={true}
        >
          {addresses.length != 0 ? (
            addresses.map(item => (
              <div className="info_partner" key={item.id}>
                <div className="map">
                  <Map lat={item.lat} long={item.long} />
                </div>

                <div>
                  <div className="icon_partern">
                    <img
                      style={{ width: '60px', marginLeft: '2px' }}
                      src={locationIcon}
                    />
                    <p style={{ position: 'relative', left: '-5px' }}>
                      {item.address}
                    </p>
                  </div>
                  <div className="icon_partern">
                    {' '}
                    <img
                      style={{ width: '30px', marginLeft: '14px' }}
                      src={phoneIcon}
                    />
                    <p>
                      <a>{contacts != undefined ? contacts.number : ''}</a>
                    </p>
                  </div>

                  <div className="icon_partern">
                    {' '}
                    <img
                      style={{ width: '30px', marginLeft: '14px' }}
                      src={mailIcon}
                    />
                    <p>
                      <a href={`mailto:${email}`} target="_blank">
                        {email}
                      </a>
                    </p>
                  </div>
                  <div className="icon_partern">
                    {' '}
                    <img
                      style={{ width: '30px', marginLeft: '14px' }}
                      src={globalIcon}
                    />
                    <p>
                      <a href={`https://${site}`} target="_blank">
                        {site}
                      </a>
                    </p>
                  </div>
                  <div className="icon_partern">
                    {' '}
                    <img
                      style={{ width: '30px', marginLeft: '14px' }}
                      src={servicesIcon}
                    />
                    <p>
                      <a href={`https://${site}`} target="_blank">
                        {servicePartner != undefined
                          ? servicePartner.service
                          : ''}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="info_partner">
              <div className="map">
                <Map lat={-0} long={-0} />
              </div>

              <div>
                <div className="icon_partern">
                  {' '}
                  <img
                    style={{ width: '30px', marginLeft: '14px' }}
                    src={phoneIcon}
                  />
                  <p>
                    <a>{contacts != undefined ? contacts.number : ''}</a>
                  </p>
                </div>

                <div className="icon_partern">
                  {' '}
                  <img
                    style={{ width: '30px', marginLeft: '14px' }}
                    src={mailIcon}
                  />
                  <p>
                    <a href={`mailto:${email}`} target="_blank">
                      {email}
                    </a>
                  </p>
                </div>
                <div className="icon_partern">
                  {' '}
                  <img
                    style={{ width: '30px', marginLeft: '14px' }}
                    src={globalIcon}
                  />
                  <p>
                    <a href={`https://${site}`} target="_blank">
                      {site}
                    </a>
                  </p>
                </div>
                <div className="icon_partern">
                  {' '}
                  <img
                    style={{ width: '30px', marginLeft: '14px' }}
                    src={servicesIcon}
                  />
                  <p>
                    <a href={`https://${site}`} target="_blank">
                      {servicePartner != undefined
                        ? servicePartner.service
                        : ''}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          )}
        </Carousel>
      </div>
    </div>
  )
}

export default Card
