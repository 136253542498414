import React,{useEffect,useState} from 'react'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import Card from '../../components/Card'
import './style.css'

import card2Icon from '../../images/card2Icon.png'
import pixIcon from '../../images/pixIcon.png'
import pixIcon2 from '../../images/pixIcon2.png'

import api from '../../services/api'

import Button from '../../components/Button'

import InputMasked from '../../components/InputMasked'


import uploadIcon from '../../images/uploadIcon.jpeg'
import copyIcon from '../../images/copyIcon.png'
import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'
import PlansMobileComponent from '../../components/PlansMobileComponent'



import BottomBarMobile from '../../components/BottomBarMobile'

import validarCartao from 'validador-cartao-credito'



const PaymentCard = () =>{

    
    const referral_link = localStorage.getItem('@REFERRAL')


    const [data,setData] = useState([])

    const [memberId,setMemberId] = useState() 

    const [name,setName] = useState('')
    const [card,setCard] = useState('')
    const [dueDate,setDueDate] = useState('')
    const [cvv,setCvv] = useState('')

    const [cardError,setCardError] = useState('')
    const [nameError,setNameError] = useState('')
    const [dueDateError,setDueDateError] = useState('')
    const [cvvError,setCvvError] = useState('')

    const [paymentError,setPaymentError] = useState()
    const [planStorage,setPlanStorage] = useState()


    const [comprovante,setComprovante] = useState()
    const [plan,setPlan] = useState()

 
    const [comprovanteError,setComprovanteError] = useState()
    const [base64Comprovante,setBase64Comprovante] = useState() 

    const [paymentCardStyle,setPaymentCardStyle] = useState('block')
    const [paymentPixStyle,setPaymentPixStyle] = useState('none')
    const [parcelas,setParcelas] = useState()

    const [gif,setGif] = useState(false)

    const [arrayCalcInstallments,setArrayCalcInstallments] = useState([])
    const [errorMessage,setErrorMessage] = useState([])

    const [cardNumberError,setCardNumberError] = useState()
    const [cvvErrorNumber,setCvvErrorNumber] = useState()
    const [responseValidateCard,setResponseValidateCard] = useState()

    const [stateDataCard,setStateDateCard] = useState(false)

    async function getPlans(){
        const response = await api.get('/plans')

        if(response.status != 200){
            return false
        }
        setData(response.data)
    }

        async function getInfoUser(){

        const response = await api.get('/recipient')

        if(response.status != 200){

            return false
        }

        setMemberId(response.data.id)

    }

    

    async function setPaymentPix(){

        setGif(true)


        if(plan == null){

            alert('Informe um plano!')
            setGif(false)

            return false
        }

        if(comprovante == null){

            setComprovanteError('Informe um comprovante!')
            return false
        }



        let response = await api.post('/payment/others',{

            member: memberId,
            type: "P",
            plan:plan == undefined ? 1 : plan,
            descriptor: "pagamento",
            receipt: base64Comprovante

        })

        setGif(true)

        
        setTimeout(() =>{

            setGif(false)

        },2000)



        if(response.status == 200){

        alert('Pagamento realizado com sucesso! entraremos em contato em breve :)')
        
        setTimeout(() =>{

            window.location.href="/dashboard"

        },2000)

        }

        if(response.status != 200){

            alert('Opss. algo deu errado!')

        }


    }


    async function setPayment(){


        setGif(true)
        if(plan == null){

            alert('Informe um plano!')
            setGif(false)

            return false
        }


        if(card == ''){

            setCardError('Informe o número do cartão')
            setGif(false)
        }

        if(name == ''){

            setNameError('Informe o titular do cartão')
        }

        if(dueDate == ''){

            setDueDateError('Informe uma data de validade')
        }

        if(cvv == ''){

            setCvvError('Informe o código de segurança')
                        
        }


        if(card != ''){
            setCardError('')
        }

        if(name != ''){
            setNameError('')
        }

        if(dueDate != ''){
            setDueDateError('')
        }

        if(cvv != ''){
            setCvvError('')
        }

        
        if(dueDate != ''){

            if( dueDate.length < 7){

                setCvvErrorNumber('Informe a validade no formato MM/AAAA')
                setGif(false)
                setStateDateCard(false)

            }
    
            if(dueDate.length  == 7 ){
    
                setCvvErrorNumber('')
                setStateDateCard(true)

            }

        }




        if(card != ''){

            let cardFormated = card.replace(' ', '')

            let cardFormated2 = cardFormated.replace(' ', '')
            let cardFormated3 = cardFormated2.replace(' ', '')
            let cardFormated4 = cardFormated3.replace(' ', '')

            if(cardFormated4.length < 16){

                setCardError('Informe um número valido')
                setGif(false)
                return false
            }



            
            let validateCard = validarCartao(cardFormated4)
    

            setResponseValidateCard(validateCard)
    
            if(validateCard == false){
                setGif(false)
                setCardError('')    
                setCardNumberError('Informe um número de cartão valido')
                return false
    
            }
           
            if(validateCard != false){
    
                setCardNumberError('')
                setGif(true)
    
            }
    


        }

        

        if(card == '' || name == '' || dueDate == '' || cvv == '' || responseValidateCard == false  || stateDataCard == false){

            setGif(false)
            return false

        }




        
        setTimeout(() =>{

            setGif(false)

        },2000)


        const response = await api.post('/payment/cards',{
   

           member:memberId,
           type:'CreditCard',
           plan:plan == undefined ? 1 : plan,
           referral:referral_link,
           descriptor:'test',
           
           dataCard: [
               {
                 cardNumber: card,
                 holder: name,
                 expirationDate: dueDate,
                 securityCode:cvv,
                 installments:parcelas

               }
             ]
   
   
       })



       

       if(response.status == 200){

        alert('Pagameto realizado com sucesso! entraremos em contato em breve :)')

        window.location.href='dashboard'

       }

       if(response.status != 200){

        setPaymentError('Não foi possivel realizar o pagamento verifique os dados e tente novamente')

       }

       }

       

    
    
    useEffect(() =>{
        getInfoUser()
        getPlans()

    },[])



    function verifyStyles(value){


        if(value == 'card-null'){

            setPaymentCardStyle('none')
            setPaymentPixStyle('block')

        }

        if(value == 'pix-null'){

            setPaymentPixStyle('none')
            setPaymentCardStyle('block')

        }


    }


    useEffect(() =>{



        console.log(parcelas)
        

        if(comprovante != null){

            setComprovanteError('')

        }

        if(comprovante){

            var reader = new FileReader()

            reader.readAsDataURL(comprovante)
    
            reader.onload = () =>{
    
                let result = reader.result
                
                setBase64Comprovante(result)
                
            }
    

        }
    })


    const array = []

    const calcInstallments = (e) =>{

        for(let i=1;i<=12;i++){

            let calc = e/i

            let response = `${i}X de R$${calc.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})} sem juros`

            array.push({name:response,id:i})

            

        }

        setArrayCalcInstallments(array)

    }
    



    return(

    <>

    <div className="container_partners">

    <div className="row_container">

    <Sidebar checked="home"/>

    
    <Header namePage="Assinar plano"/>

    </div>


    <div className="column_paymentCard">

  

    <div className="list_indications_card">

    <h2>Planos</h2>
    
    
    {data.map(item => 
    
    
    <div className="container_radio">
    <input type="radio" id={item.id} name="1" onChange={() => setPlan(item.id)} onClick={() => calcInstallments(item.value)}/>

    <div className="data_container_radio">
    <label htmlFor={item.id}> {item.type == 'Y'?'Cartão anjo Plano anual' :'Cartão anjo Plano mensal' } {item.value.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})}</label>
    </div>

    </div>


    )}
        
        

    </div>






    <div className="methodPayment" style={{display:paymentCardStyle}}>
    
    <div className="selectMethodPayment">

    <div className="creditCard">
    <img src={card2Icon}/>
    <p>Cartão</p>
    </div>

    <div className="pix" onClick={() => verifyStyles('card-null')}>
    <img src={pixIcon}/>
    </div>

    </div>
    {paymentError != null ? <p className="errorPayment">Não foi possivel realizar o pagamento!<br/> tente novamente.</p> :''}

    <div className="container_inputs_payment">
    

    <div className="input">
    <label>Número do cartão</label>
    <InputMasked mask="9999 9999 9999 9999" onChange={(e) => setCard(e.target.value)}/>
    {cardError != '' ? <p className="errorMessage_card">{cardError}</p> : ''}
    {cardNumberError != '' ? <p className="errorMessage_card">{cardNumberError}</p> : ''}

    </div>

    <div className="input">
    <label>Nome no cartão</label>
    <input type="text" onChange={(e) => setName(e.target.value)} />
    {nameError != '' ? <p className="errorMessage_card">{nameError}</p> : ''}

    </div>


    <div className="row_input">

    <div className='container_validate_input_desktop'>
    <label>Validade</label>
    <InputMasked mask="99/9999" onChange={(e) => setDueDate(e.target.value)}/>
    {dueDateError != '' ? <p className="errorMessage_card">{dueDateError}</p> : ''}
    {cvvErrorNumber != '' ? <p className="errorMessage_card">{cvvErrorNumber}</p> : ''}

    
    </div>

    <div className="container_validate_input_desktop">
    <label>Código cvv</label>
    <InputMasked mask="999" onChange={(e) => setCvv(e.target.value)}/>
    {cvvError != '' ? <p className="errorMessage_card">{cvvError}</p> : ''}
    </div>
    </div>
    <div className="input_container_payment_card_mobile_select" style={{width:'80%'}}>
    <label>Número de parcelas</label>
    <select onChange={(e) => setParcelas(e.target.value)}>
    {arrayCalcInstallments.map(item => {
    return <option value={item.id}>{item.name}</option>
        
    })}
    </select>
    </div>

    <div className="clean"></div>
    <Button text="Assinar plano" gif={gif} onClick={setPayment}/>


    </div>
    </div>



    <div className="methodPayment" style={{display:paymentPixStyle}}>
    
    <div className="selectMethodPayment">

    <div className="creditCard_not_select" onClick={() => verifyStyles('pix-null')}>
    <img src={card2Icon}/>
    <p>Cartão</p>
    </div>

    <div className="pix_select">
    <img src={pixIcon2}/>
    </div>

    </div>

    <div className="container_inputs_pix">
    
    <div className="input">
    <label>Chave PIX</label>
    <InputMasked className="input_copy" mask="99.999.999/9999-99" value="34.410.302/0001-50" onClick={() => {navigator.clipboard.writeText(`34.410.302/0001-50`)}}/>
    <img src={copyIcon} className="copy_icon"/>
    </div>

    {comprovante != null ? <p className="name_file">{comprovante.name}</p>:''}

    <label htmlFor='file'><img src={uploadIcon} /></label>

    <input type="file" id="file" accept="image/png,image/jpeg" name="file" onChange={(e) => setComprovante(e.target.files[0])}/>
    {comprovanteError != null ? <p className="messageErrorComprovante">{comprovanteError}</p> :''}
    <div className="clean"></div>
    <Button text="Assinar plano" gif={gif}  onClick={setPaymentPix}/>


    </div>
    </div>


    </div>
    </div>



        <div className="mobile_layout_payment_card">
        <HeaderDashboardMobile icon={false} page="login" />
        
        <div className="plans_mobile_container">

        <h2>Planos</h2>


        {data.map(item => {
        
        return  <PlansMobileComponent value={item.value} type={item.type} id={item.id}/>

        })}



        </div>

        
        </div>

  
    </>

    )
}

export default PaymentCard