import React, { useState,useEffect } from 'react'
import moment from "moment";

import handShakeIcon from '../../images/walletIcon2.png'

const Transactions  = ({plan,date,value,type,situation}) =>{
    const [dateFormated,setDateFormated] = useState()
    
        useEffect(() =>{
        let dataFormated = moment.utc(date).format('DD/MM/YYYY')
        setDateFormated(dataFormated)
        },[])

    return(

        <tr className="test3">
        <td> <img src={handShakeIcon} style={{width:'38px',marginRight:'5px'}}/>{type == 'A' ? 'Anual' : 'Mensal'}</td>
        <td style={{position:'relative',top:'5px'}}>{dateFormated != null ? dateFormated : ''}</td>
        <td style={{position:'relative',top:'5px'}}>{value != null ? value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : ''}</td>
        <td style={{position:'relative',top:'5px'}}>{situation != null ? 'Ativo' :'Desativado'}</td>

        
        </tr>
    

    )


}

export default Transactions