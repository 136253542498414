import React from 'react'
import './style.css'

const CheckboxMobile = ({text,id,onChange}) =>{

    return(

        <div className="container_checkbox_mobile">
        <input type="checkbox" className="checkbox_mobile" id={id} onChange={onChange} />
        <label htmlFor={id}>{text}</label>
        </div>
    )

}

export default CheckboxMobile