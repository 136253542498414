import React from 'react'
import './style.css'

const InputMobile = ({type,onChange,placeholder,width,onClick,onBlur,value}) =>{

    return(

        <input type={type} className="input_mobile" placeholder={placeholder} value={value} style={{width:width}} onChange={onChange} onClick={onClick} onBlur={onBlur} />

        
    )

}

export default InputMobile