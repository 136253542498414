import React,{useEffect} from 'react'
import HeaderMobile from '../../components/HeaderMobile'

import mailBackground from '../../images/mailBackgroundIcon.png'
import ButtonMobile from '../../components/ButtonMobile'
import api from '../../services/api'

import './style.css'

const SucessSendMailMobile = () =>{

    const mail = localStorage.getItem('@mail_recover')


    async function sendMailRecover(){

        let status = await api.post('reset-password',{
 
            email:mail
    
            })
    
            if(status.status == 200){
   
               
            }
    
    
            if(status.status == 405){
    
                alert('email não encontrado')
    
            }

    }

    async function sendMailRecoverAgain(){

        let status = await api.post('reset-password',{
 
            email:mail
    
            })
    
            if(status.status == 200){
   
                alert('Dados enviados novamente!')
               
            }
    
    
            if(status.status == 405){
    
                alert('Email não encontrado')
    
            }

    }



    useEffect(() =>{

        sendMailRecover()
    },[])

    return(

        <>
        <HeaderMobile page="forgot-password-mobile"/>

        <div className="container_sucess_send_mail_mobile">

        <h2>E-mail enviado!</h2>
        <p>As intruções foram enviandas para o e-mail <br/>  abaixo: {mail}</p>

        <div className="container_image_sucess_send_mail_mobile">
        <img src={mailBackground}/>
        
        <div className="flex_container_image_sucess_send_mail_mobile">
        <p className="not_receive">Não recebeu?</p>
        <p className="send" onClick={sendMailRecoverAgain}>Reenviar</p>

        </div>


        </div>

        <div className="button_sucess_send_mail_mobile">
        <ButtonMobile text="Entendi" onClick={() => window.location.href="/login"}/>
        </div>


        </div>


        </>

    )

}

export default SucessSendMailMobile