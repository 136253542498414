import React,{useEffect,useState} from 'react'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import Card from '../../components/Card'
import './style.css'

import api from '../../services/api'


import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'
import BottomBarMobile from '../../components/BottomBarMobile'

import mamaIcon from '../../images/mamaIcon.png'

import neuramastericon from '../../images/neuramastericon.png'


import cartaoanjo from '../../images/iconCartaoAnjo.png'

import walletIcon from '../../images/walletIcon.png'
import handShakeIcon from '../../images/handShakeIcon.png'
import walletIcon2 from '../../images/wallet2Icon.png'
import exitIcon from '../../images/exitIcon.png'



const PartnersNotLogged = () =>{

    const [data,setData] = useState([])



    async function getDataPartners(){

        const test = await api.get('/partners')
        setData(test.data)
        }


        useEffect(() =>{
            
            getDataPartners()

        },[])


    const exitApp = () =>{

        localStorage.removeItem('@TOKEN')
        window.location.href="/"

    }


        const redirectPartnersMobile = (e) => {


            localStorage.setItem('@name_partner',e.name)
            localStorage.setItem('@phone',e.name)
            localStorage.setItem('@mail',e.email)
            localStorage.setItem('@site',e.site)
            localStorage.setItem('@service',JSON.stringify(e.services))
            localStorage.setItem('@base64Logo',e.base64Logo)



            localStorage.setItem('@address_partner',JSON.stringify(e.addresses))
            window.location.href="/partners-info-mobile"
        }


        const [pages,setPages] = useState(false)
        const [expire,setExpire] = useState()
        const [token,setToken] = useState()
        
        async function getDataUser(){
        
            let data = await api.get('/recipient')       
            setExpire(data.data.expire)
        
        
            if(expire == null){
        
                setPages(false)
        
            }
        
        
            if(expire != null){
        
                setPages(true)
        
            }
            
        }
        



    return(

    <>
    <div className="container_partners">

    <div className="row_container">
    
    <div>







    <div className="sidebar">
    
    <div className="icon_card_container" onClick={() => expire != null ? window.location.href="/dashboard" : window.location.href="/"}>
    <img src={cartaoanjo} className="icon_card"/>
    </div>
    
    <nav>
    <ul>

    <div className="flex_icons"><img src={handShakeIcon}/><li className="checked" onClick={() => window.location.href="partners"} >Parceiros</li></div>

    </ul>
    </nav>

  

    <>
    <div className="exit">

    <img src={exitIcon} onClick={() => exitApp()}/>
    <p onClick={() => exitApp()}>Sair</p>

    </div>

    </>




    </div>





    </div>
    
    <Header namePage="Lista de parceiros" page="partners"/>

    </div>


    <div className="column3_cards_part">

    <div className="container_cards_partners">

    
    {data.map(item => <Card key={item.id} image={item.base64Logo} addresses={item.addresses} name={item.name} servicePartner={item.services[0]} email={item.email} site={item.site} contacts={item.contacts[0]} lat={item.lat} long={item.long}  />)}

    </div>


    </div>
    </div>

    <>


        
        <div className="container_partners_mobile__2">

        <HeaderDashboardMobile icon={false} page="dashboard-mobile" />


        <h2>Lista de parceiros</h2>


  
        <div className="flex_partner_mobile">


        {data.map(item => {

        return(

        <>  
        <div className="card_partner_mobile" onClick={(() => redirectPartnersMobile(item))}>

        <img src={`data:image/;base64,${item.base64Logo}`}/>

        <button>Veja mais</button>

        </div>

        </>

        )

        })}

        
 



    
        </div>


        <BottomBarMobile/>

        </div>





    </>



    </>

    )
}

export default PartnersNotLogged