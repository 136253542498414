import React,{useState,useEffect} from 'react'

import Button from '../../components/Button'
import Input from '../../components/Input'
import InputMasked from '../../components/InputMasked'
import Select from '../../components/Select'

import './style.css'
import iconCartaoAnjo from '../../images/iconCartaoAnjo.png'
import cartaoanjo from '../../images/cartaoAnjo.png'
import mailICon from '../../images/mailBackgroundIcon.png'

import api from '../../services/api'

const SucessSendMail = () =>{

 
    const mail = localStorage.getItem('@EMAIL')


    async function sendEmail(){

        let status = await api.post('reset-password',{
 
         email:mail
 
         })
 
         if(status.status == 200){

            alert('Dados enviados novamente!')
            
         }
 
 
         if(status.status == 405){
 
             alert('email não encontrado')
 
         }
         
     }



    return(
        <>
        <div className="container_fluid">

        
            <div className="column1">
            <img src={cartaoanjo} className="icon_card"/>
            
            <h1 className="textPrimary">Cartão Anjo</h1>
            <h2 className="textSecondary">Cuida da sua saúde</h2>
            <p className="textDescription">O CARTÃO ANJO CLUB é para todos. Não existem <br/> limitações, impedimentos de idade, carências,<br/> restrições a grau de parentesco, gestantes, doenças<br/> preexistentes ou crônicas.</p>
            <a href='https://cartaoanjo.com.br' className="info" target='_blank'>Saiba mais</a>
            
            <div className="buttons">
            <Button text="Já sou cadastrado"  onClick={() => window.location.href="/login"} />
            <Button text="Cadastre-se"  onClick={() => window.location.href="/register"} />
            <Button text="Parceiros" onClick={() => window.location.href="/partners"}/>
            </div>


            </div>

            <div className="column2">
    
            <div className="container_inputs_logIn">
            <img src={iconCartaoAnjo} className="icon_container_inputs_login"/>
            <h2>E-mail enviado com sucesso!</h2>
            <p>As intruções foram enviandas para o e-mail abaixo: <br/> {mail}</p>

            <img src={mailICon} className="icon_mail_inputs_login"/>
            <div className="row_inputs_login">
            <Button text="Entendi" onClick={() => window.location.href="/login"}/>
            <a  onClick={() => sendEmail()}>Não recebeu?</a>
            </div>
            
            </div>

            </div>

        </div>

        </>



    )


}

export default SucessSendMail