import React,{useState,useEffect} from 'react'

import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'
import BottomBarMobile from '../../components/BottomBarMobile'

import cardIcon from '../../images/cartaoAnjo.png'
import DashboardsMobile from '../../components/Dashboards-Mobile'

import './style.css'

import api from '../../services/api'
import moment from "moment";
import verifyUser from '../../functions/verifyUser'

const DashboardMobile  = () => {
    
    verifyUser()

    const [balance,setBalance] = useState()
    const [quantityIndication,setQuantityIndication] = useState()
    const [expire,setExpire] = useState()
    
    const [name,setName] = useState()

    const [referral,setReferralLink] = useState()
    const [inputText, setInputText] = useState('');
    const [expireFormated,setExpireFormated] = useState()



    async function getData(){

        const data = await api.get('/recipient')


        setQuantityIndication(data.data.quantityIndication)
        setBalance(data.data.balance)
        setExpire(data.data.expire)
        setName(data.data.user.name)
        setReferralLink(data.data.referral)

        let dataFormated = moment.utc(data.data.expire).format('DD/MM/YYYY')
        setExpireFormated(dataFormated)


    }

    useEffect(() =>{

        getData()

    },[])


    return(

        <>
        <HeaderDashboardMobile verifyBackIcon={false}/>

        <div className="dashboard_mobile">

        <h2>Inicio</h2>

        <div className="icon_card_dashboard_mobile">

        <img src={cardIcon}/>

        <h2 className="name_dashboard_mobile">{name}</h2>
        <h2 className="validate_dashboard_mobile">Validade {expireFormated != null ? expireFormated : ''}</h2>

        </div>

        <div className="container_dashboards_dashboard_mobile">
        <DashboardsMobile/>
        </div>

        


        </div>

        <BottomBarMobile/>

        </>
    )

}

export default DashboardMobile