import InputMask from 'react-input-mask';


const InputMasked = ({mask,placeholder,onChange,value,disabled,onBlur}) =>[

    
    <InputMask type="text" disabled={disabled}  onBlur={onBlur} maskChar={null}  mask={mask} placeholder={placeholder} Autofill={false} onChange={onChange} value={value} />


]

export default InputMasked