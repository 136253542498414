import React,{useState,useEffect} from 'react'

import Button from '../../components/Button'
import Input from '../../components/Input'
import InputMasked from '../../components/InputMasked'
import Select from '../../components/Select'

import './style.css'
import iconCartaoAnjo from '../../images/iconCartaoAnjo.png'
import cartaoanjo from '../../images/cartaoAnjo.png'

import api from '../../services/api'

import {useParams} from 'react-router-dom'

import InputMobile from '../../components/InputMobile'
import ButtonMobile from '../../components/ButtonMobile'
import HeaderMobile from '../../components/HeaderMobile'

import backgroundImage from '../../images/backgroundIcon.png'

const ForgotPassword = () =>{

    const [mail,setMail] = useState()

    const [mailError,setMailError] = useState()

    const [gif,setGif] = useState(false)

    async function sendEmail(){


        if(mail == null){

        setMailError('Informe um email')
        
        return false 

        }

        setGif(true)


        let status = await api.post('reset-password',{
 
         email:mail
 
         })
 
         if(status.status == 200){
             localStorage.setItem('@EMAIL',mail) 
             window.location.href="/sucess-sendmail"
 
         }

         setGif(false)
 
 
         if(status.status == 405){
 
             alert('email não encontrado')
 
         }
         
     }


     async function sendMailMobile(){

        if(mail == null){

            setMailError('Informe um email')
            
            return false 
    
            }
    
            setGif(true)
    
    
            let status = await api.post('reset-password',{
     
             email:mail
     
             })
     
             if(status.status == 200){
                 localStorage.setItem('@EMAIL',mail) 
                 window.location.href="/sucess-send-mail-mobile"
     
             }
    
             setGif(false)
     
     
             if(status.status == 405){
     
                 alert('email não encontrado')
     
             }

     }
 

    return(
        <>
        <div className="container_fluid">

        
            <div className="column1">
            <img src={cartaoanjo} className="icon_card"/>
            
            <h1 className="textPrimary">Cartão Anjo</h1>
            <h2 className="textSecondary">Cuida da sua saúde</h2>
            <p className="textDescription">O CARTÃO ANJO CLUB é para todos. Não existem <br/> limitações, impedimentos de idade, carências,<br/> restrições a grau de parentesco, gestantes, doenças<br/> preexistentes ou crônicas.</p>
            <a href='https://cartaoanjo.com.br/' className="info" target='_blank'>Saiba mais</a>
            
            <div className="buttons">
            <Button text="Já sou cadastrado" onClick={() => window.location.href="/logIn"}/>
            <Button text="Cadastre-se" onClick={() => window.location.href="/register"}/>
            <Button text="Parceiros" onClick={() => window.location.href="/partners"}/>
            </div>


            </div>

            <div className="column2">
    
            <div className="container_inputs_logIn">
            <img src={iconCartaoAnjo} className="icon_container_inputs_login"/>
            <h2>Esqueceu a senha ?</h2>
            
            <div className="input_mail_desktop">
            <input type="email" placeholder="E-mail" onChange={(e) => setMail(e.target.value)} />            
            
            <div className="message_error_input_mail_desktop">
            {mailError != null ? <p className="mail_error_desktop">{mailError}</p> : ''}
            <div></div>
            </div>


            </div>


            <div className="row_inputs_login">
            <Button text="Recuperar senha" onClick={sendEmail} gif={gif}/>
            <a href="login">Lembrou da senha?</a>
            </div>
            
            </div>

            </div>

        </div>

        <>
        <div className="mobile_layout_forgot_password">
        <HeaderMobile page="login"/>

        <div className="container_forgot_password_mobile">

        <h2 className="text_primary_forgot_password">Esqueceu a senha?</h2>

        <p>Não se preocupe! Digite seu e-<br/>mail para receber instruções de <br/> recuperação de senha</p>

        <div className="container_image_forgot_password_mobile">
        <img src={backgroundImage}/>
        </div>

        <div className="container_input_forgot_password_mobile">


        <InputMobile placeholder="E-mail" onChange={(e) => setMail(e.target.value)}/>

        <div className="row_forgot_password_mobile">

        <p className="remember_forgot_password_mobile">Lembrou da senha?</p>
        <p className="login_forgot_password_mobile" onClick={() => window.location.href="/login"}>Entrar</p>
        </div>
        
        <div className="container_button_forgot_password_mobile">
        <ButtonMobile text="Recuperar Senha" gif={gif}  onClick={sendMailMobile}/>

        <p className="remember_forgot_password_mobile">Não tem uma conta?</p>
        <p className="login_forgot_password_mobile" onClick={() => window.location.href="/register"}>Cadastre-se</p>

        </div>

        </div>


        </div>

        </div>
        </>




        </>







    )


}

export default ForgotPassword