import React,{useEffect,useState} from 'react'
import Sidebar from '../../components/Sidebar'
import Header from '../../components/Header'
import Card from '../../components/Card'
import './style.css'
import moment from "moment";

import api from '../../services/api'

import cartaoanjo from '../../images/cartaoAnjo.png'
import porcoIcon from '../../images/porcoIcon.png'
import punhosIcon from '../../images/handShakeIcon.png'
import copyIcon from '../../images/copyIcon.png'
import shareIcon from '../../images/shareIcon.png'

import verifyUser from '../../functions/verifyUser'

import Indications from '../../components/Indications'

import cartaoExpires from '../../images/cartaoExpires.png'

import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'
import BottomBarMobile from '../../components/BottomBarMobile'

import cardIcon from '../../images/cartaoAnjo.png'
import DashboardsMobile from '../../components/Dashboards-Mobile'


const Dashboard = () =>{



    const [data,setData] = useState([])

    const [balance,setBalance] = useState()
    const [quantityIndication,setQuantityIndication] = useState()
    const [expire,setExpire] = useState(1)

    const [name,setName] = useState()
    const [referral,setReferralLink] = useState()
    const [expireFormated,setExpireFormated] = useState()

    /* Images User */

    const [imageUserHeader,setImageUserHeader] = useState()
    const [nameUserHeader,setNameUserHeader] = useState()
    const [responseVerifyUser,setResponseVerifyUser] = useState()

    const [expireFormatedVerify,setExpireFormatedVerify] = useState()

    const [plaState,setPlanState] = useState()



    async function getData(){

        const data = await api.get('/recipient')

        if(data.status != 200){

          alert(data.data[0])

          return false

        }


        setResponseVerifyUser(data.data.message)
        
        setQuantityIndication(data.data.quantityIndication)
        setBalance(data.data.balance)
        setExpire(data.data.expire)

        setName(data.data.user.name)
        setReferralLink(data.data.referral)
        let dataFormated = moment.utc(data.data.expire).format('DD/MM/YYYY')
       
        let dateFormatedExpire = moment.utc(data.data.expire).format('YYYY/MM/DD')

        
        setExpireFormated(dataFormated)
        setExpireFormatedVerify(dateFormatedExpire)

        localStorage.setItem('@expireFormatedVerify',dateFormatedExpire)
        localStorage.setItem('@name',data.data.user.name)

        localStorage.setItem('@referralLink',data.data.referral)
        localStorage.setItem('@balance',data.data.balance)
        localStorage.setItem('@quantityIndication',data.data.quantityIndication)


        setImageUserHeader(data.data.user.imagemBase64)
        setNameUserHeader(data.data.user.name)

    }



    async function getDataIndications(){

      const response = await api.get('/commisions')
      if(response.status == 200){

        setData(response.data)
      }

      setData([])



    }



    const constCopyData = () =>{
      
      navigator.clipboard.writeText(`https://central.cartaoanjo.com.br/register/${referral}`).then(function() {

      alert('Dados copiados com sucesso!')
      

    }, function() {

      alert('opss.. algo deu errado!')

    });

    }

    const verifyExpiresInCard = () =>{

      let dateNow = new Date()
      dateNow.toDateString()

      let dateFromApi =  localStorage.getItem('@expireFormatedVerify')



      dateNow = new Date(dateNow)
      dateFromApi = new Date(dateFromApi)


      /* Checks if the current date is greater than the plan date, if the plan is expired */



      if(dateFromApi == 'Invalid Date'){

        setPlanState(false)
        return false

      }


      if(dateNow  >= dateFromApi){
       setPlanState(false)
      }else{
      setPlanState(true)
      }
       

    }


    const getDataLocal = () =>{

      let name = localStorage.getItem('@name')
      let referralLink = localStorage.getItem('@referralLink')
      let balance = localStorage.getItem('@balance')
      let quantityIndication = localStorage.getItem('@quantityIndication')

      setName(name)
      setReferralLink(referralLink)
      setBalance(balance)

      setQuantityIndication(quantityIndication)


    }
    

    useEffect(() =>{
      verifyUser(responseVerifyUser)

      getDataIndications()
      getData()
      getDataLocal()

  },[])


    useEffect(() =>{
      
      verifyExpiresInCard()

    })



      return(


        <>
        <div className="container_partners">

        <div className="row_container">

        <Sidebar checked="home"/>


        <Header namePage="Inicio" imageUser={imageUserHeader} nameUser={nameUserHeader}/>
        </div>


        <div className="column3">

        <div className="dashboard">

        <div className="info_user">
        

        {plaState == false || plaState == null ?

        <>
        <img src={cartaoExpires} className="card_expires" onClick={() => window.location.href="/plans"}/>
        
    

        </>

        :
        
        <>
        <img src={cartaoanjo}/>
        <p className="data_user_primary_name">{name}</p>
        <p className="data_user_secondary_valid">Validade</p>
        <p className="data_user_validate">{expireFormated}</p>
        </>
        

        }

    
        </div>


        <div className="data_user" style={{height:'311px'}}>

        <h2 className="textPrimary_data_user">Indicações</h2>

        <div className="row_data_user">
        <div className="card_data_user">
        <h2>N° de indicações</h2>

        <div className="icon_card_data_user_2">
        <img src={punhosIcon}/>
        <h2>{quantityIndication}</h2>
        </div>

        </div>

        <div className="card_data_user">
        <h2>Saldo</h2>
        <div className="icon_card_data_user">
        <img src={porcoIcon}/>
        <h2>{balance != null ? balance.toLocaleString('pt-br',{style:'currency',currency:'BRL'}) : `${`R$ 0,00`}`}</h2>

        </div>

        </div>
        </div>


        {referral == undefined ? '' 
        
        :
      

        <div className="row_data_user">
        <div className="card_data_user_share">
        <h2>Compartilhe seu link de indicação</h2>

        <div className="row_card_data_user_share">
        <div className="icon_card_data_user" onClick={constCopyData } >
        <img src={copyIcon}/>
        <h2>Copiar Link</h2>
        </div>

        <div className="icon_card_data_user" onClick={constCopyData}>
        <img src={shareIcon}/>
        <h2>Compartilhar</h2>
        </div>
        </div>

        </div>
        </div>


        }
        
        
        </div>
        </div>


        <div className="list_indications_dashboard">

        <h2>Lista de indicados</h2>
        <table class="table table-borderless">
        <thead className="test">
          <tr className="test2">
            <th scope="col">Beneficiário</th>
            <th scope="col">Plano</th>
            <th scope="col">Comissão</th>
            <th scope="col">Situação</th>
          </tr>
        </thead>
        <tbody>

        {data.map(item => <Indications key={item.id} name={item.payment.member.user.name} plan={item.plan_id}  state={item.payment.member.user.status} member={item.payment.member.user.name} balance={item.value} type={item.payment.type}/>)}

        </tbody>
       </table>
        </div>
        </div>
        </div>


        <div className="container_dashboard_mobile__desktop">
        <div className="dashboard_mobile">
        <HeaderDashboardMobile verifyBackIcon={false} imageUser={imageUserHeader}/>

        <h2>Inicio</h2>

        <div className="icon_card_dashboard_mobile">


        {plaState == false 
        ?  

        <img src={cartaoExpires} className="card_expires_mobile" onClick={() => window.location.href="/plans"}/>

        :
        
        <>
        
        <img src={cardIcon}/>
        <h2 className="name_dashboard_mobile">{name}</h2>
        <h2 className="validate_dashboard_mobile">Validade {expireFormated != null ? expireFormated : ''}</h2>

        </>
        }

        </div>

        <div className="container_dashboards_dashboard_mobile">
        <DashboardsMobile referralLink={`https://central.cartaoanjo.com.br/register/${referral}`} quantityIndication={quantityIndication} balance={balance}/>
        </div>



        </div>
        <BottomBarMobile/>

        </div>


        </>





        )


}

export default Dashboard
