import React from 'react'
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';

const MapMobile = ({lat,long}) =>{

    console.log(lat)
    console.log(long)

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD7AA9nnKYrUc4pprxQf53QJf_t5RDLxDE"
      })

      const center = {

        lat: Number(lat),
        lng: Number(long)

      }
      return isLoaded ? (
        <GoogleMap
          mapContainerStyle={{width:'270px',height:'180px',borderRadius:'20px',marginTop:'50px',marginBottom:'77px'}}
          center={center}
          zoom={10}
        >
        <Marker position={center} />
          <></>
        </GoogleMap>
    ) : <></>


}

export default MapMobile