import React,{useState,useEffect} from 'react'

import walletIcon from '../../images/walletIcon2.png'

import './style.css'
import moment from "moment";

import InputMaskedMobile from '../../components/InputMaskedMobile'

const HistoricTransactionsMobileComponent = ({plan,date,value,type,situation}) => {

    const [plan2,setPlan] = useState()

    let dataFormated = moment.utc(date).format('DD/MM/YYYY')


    useEffect(() =>{

        if(type == 'A'){

            setPlan('Plano Anual')
        }

    
        if(type != 'A'){

            setPlan('Plano Mensal')


        }



    })


    return(

        <div className="historic_transactions_mobile_component">

        <div className="row_historic_transactions_mobile_component">

        <img src={walletIcon}/>



       <div className="data_historic_transactions_mobile_component">

        <p>{type == 'P' ? 'Plano Anual' : 'Plano Mensal'}</p>
        <p>{dataFormated}</p>
        </div>


        <div className="data_historic_transactions_mobile_component">

        <p>Valor</p>
        <p>{value.toLocaleString('pt-br',{style:'currency',currency:'BRL'})}</p>
        </div>

        <div className="data_historic_transactions_mobile_component">

        <p>Situação</p>
        <p>{situation != undefined ? 'Ativo' : 'Desativado'}</p>
        </div>


        </div>

    </div>


    )


}

export default HistoricTransactionsMobileComponent