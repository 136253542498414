import React,{useState,useEffect} from 'react'
import handShakeIcon from '../../images/hanshakeIcon.png'
import walletIcon from '../../images/walletIcon.png'
import walletIcon2 from '../../images/wallet2Icon.png'

import bottomBarIcon from '../../images/bottomBarIcon.jpeg'

import './style.css'

const BottomBarMobile = () =>{
    
    return(


        <div className="flex_bottom_bar_mobile">

        <div className="icon_parters_bottom_bar_mobile" onClick={() => window.location.href="/partners"}>

        <img src={handShakeIcon}/>
        <p>Parceiros</p>

        </div>

        <div className="icon_dashboard_bottom_bar_mobile" onClick={() => window.location.href="/dashboard"}>

        <img src={walletIcon}/>
        </div>

        <div className="icon_parters_bottom_bar_mobile" onClick={() => window.location.href="/historic-transactions-mobile"} >

        <img src={walletIcon2}/>
        <div style={{width:'50px'}}></div>
        <p>Perfil</p>

        </div>

        <img src={bottomBarIcon}/>

        </div>

     

    )

}

export default BottomBarMobile