import React,{useState,useEffect} from 'react'

import './style.css'

import iconCartaoAnjo from '../../images/iconCartaoAnjo.png'

import backIcon from '../../images/backIcon2.png'

import userIcon from '../../images/userProfile.png'
import api from '../../services/api'

const HeaderDashboardMobile =  ({icon,page,verifyBackIcon}) =>{

    const [image,setImage] = useState()

    const redirectToPageSelect = () => {

        window.location.href=`/${page}`

    }

    const [expire,setExpire] = useState()

    async function getInfoUser(){

        const response = await api.get('/recipient')

        if(response.status == 200){

            setImage(response.data.user.imagemBase64)
            setExpire(response.data.expire)

        }else{

            setImage("")
            setExpire("")
        }
        

    }

    useEffect(() =>{

        getInfoUser()


    },[])



    return(

        <div className="header_dashboard_mobile">
        
        {verifyBackIcon == false ? <div></div> 
        
        :
        <img src={backIcon} onClick={redirectToPageSelect} className="back_icon_header_dashboard_mobile"/>

        }


        <img src={iconCartaoAnjo} className="icon_header_dashboard_mobile2"/>

        {image != null ? 
    
        <img  src={`data:image/;base64,${image}`}  className="user_icon_header_dashboard_mobile" onClick={() => window.location.href="/profile"}/>

    
        :
        
        icon == false ? <div></div> :<img src={userIcon} className="user_icon_header_dashboard_mobile" onClick={() => window.location.href="/profile"}/>

    
        }
       
       
        </div>


    )

}

export default HeaderDashboardMobile