import React from 'react'
import {BrowserRouter as Router,Route,Routes} from 'react-router-dom'

import Landing from '../pages/Landing'
import Partners from '../pages/Partners'
import Dashboard from '../pages/Dashboard'
import PaymentCard from '../pages/PaymentCard'
import PaymentPix from '../pages/PaymentPix'
import LogIn from '../pages/LogIn'
import ForgotPassword from '../pages/ForgotPassword'
import SucessSendMail from '../pages/SucessSendMail'
import SucessRegister from '../pages/SucessRegister'
import Profile from '../pages/Profile'
import TokenLogin from '../pages/JwtLogin'

import PartnersNotLogged from '../pages/PartnersNotLogged'


import RegisterMobileStep2 from '../pages/RegisterMobileStep2'
import SucessPageMobile from '../pages/SucessPageMobile'
import ForgotPasswordMobile from '../pages/ForgotPasswordMobile'

import SucessSendMailMobile from '../pages/SucessSendMailMobile'
import PlansMobile from '../pages/PlansMobile'
import PaymentCardMobile from '../pages/PaymentCardMobile'

import PaymentPixMobile from '../pages/PaymentPixMobile'
import PartnersMobile from '../pages/PartnersMobile'
import PartnerInfoMobile from '../pages/PartnerInfoMobile'

import DashboardMobile from '../pages/Dashboard-Mobile'
import HistoricTransactionsMobile from '../pages/HistoricTransactionsMobile'
import IndicationsMobile from '../pages/IndicationsMobile'

import ProfileMobile from '../pages/ProfileMobile'
import LogInMobile from '../pages/LogInMobile'
import NotFound from '../pages/NotFound'

const AppStack = () =>{

  return(

    <Router>
    <Routes>
    
    <Route path='/' exact  element={<TokenLogin/>} />
    <Route path='/register'  element={<Landing/>} />
    <Route path='/register/:id'  element={<Landing/>} />
    
    <Route path='/register-mobile-step2/'  element={<RegisterMobileStep2/>} />
    <Route path='/partners' element={<Partners/>} />
    <Route path='/dashboard' element={<Dashboard/>} />
    
    <Route path='/plans' element={<PaymentCard/>} />
    <Route path='/login' element={<LogIn/>} />
    <Route path='/login/:id' element={<LogIn/>} />

    <Route path='/forgot-password' element={<ForgotPassword/>} />
    
    <Route path='/sucess-sendmail' element={<SucessSendMail/>} />
    <Route path='/sucess-register' element={<SucessRegister/>} />
    <Route path='/profile' element={<Profile/>} />

    <Route path='/partners-not-logged' element={<PartnersNotLogged/>} />


    <Route path='/sucess-page-mobile' element={<SucessPageMobile/>} />
    <Route path='/forgot-password-mobile' element={<ForgotPasswordMobile/>} />
    <Route path='/sucess-send-mail-mobile' element={<SucessSendMailMobile/>} />
    
    <Route path='/plans-mobile' element={<PlansMobile/>} />
    <Route path='/payment-card-mobile' element={<PaymentCardMobile/>} />
    <Route path='/payment-pix-mobile' element={<PaymentPixMobile/>} />

    <Route path='/partners-mobile' element={<PartnersMobile/>} />
    <Route path='/partners-info-mobile' element={<PartnerInfoMobile/>} />
    <Route path='/dashboard-mobile' element={<DashboardMobile/>} />

    <Route path='/historic-transactions-mobile' element={<HistoricTransactionsMobile/>} />
    <Route path='/indications-mobile' element={<IndicationsMobile/>} />
    <Route path='/profile-mobile' element={<ProfileMobile/>} />

    <Route path='/login-mobile' element={<LogInMobile/>} />

    <Route path='/*' element={<NotFound/>} />

    </Routes>
    </Router>
    )
  

}

export default AppStack