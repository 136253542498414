import React,{useState,useEffect} from 'react'

import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'
import BottomBarMobile from '../../components/BottomBarMobile'

import mapIcon from '../../images/mapIcon.png'
import mamaIcon from '../../images/mamaIcon.png'

import setCimaIcon from '../../images/setCimaIcon.png'
import locationIcon from '../../images/locationIcon.png'

import phoneIcon2 from '../../images/phoneIcon2.png'
import mailIcon2 from '../../images/mailIcon2.png'
import globalIcon from '../../images/globalIcon.png'

import servicesIcon from '../../images/servicesIcon.png'

import arrowLeft from '../../images/arrowLeft.png'
import arrowRight from '../../images/ArrowRight.png'


import './style.css'


import Carousel from 'nuka-carousel';

import MapMobile from '../../components/MapMobile'



const PartnerInfoMobile = () =>{


    const [token,setToken] = useState()
  
    let namePartner  =  localStorage.getItem('@name_partner')
    let adressesPartners = localStorage.getItem('@address_partner')

    let mailPartner = localStorage.getItem('@mail')
    let sitePartner = localStorage.getItem('@site')

    let services = localStorage.getItem('@service')
    let base64Logo = localStorage.getItem('@base64Logo')
    

    
    

    const array = JSON.parse(adressesPartners)

    

    
    async function getToken(){

      const token = localStorage.getItem('@TOKEN')
      setToken(token)

    }


    useEffect(() =>{
      
      getToken()


    },[])

    return(

        
        

        <>
        <HeaderDashboardMobile icon={false} page="partners"/>


        <Carousel

     
          renderCenterLeftControls={({ previousSlide }) => (
            <button onClick={previousSlide}  style={{background:'none',position:'relative',top:'-330px',border:'none'}}><img src={arrowLeft} style={{width:'30px'}}/></button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button onClick={nextSlide} style={{background:'none',position:'relative',top:'-330px',border:'none'}}><img src={arrowRight} style={{width:'30px'}}/></button>
          )}
        
        > 

        
        {array.length == 0 
        
        ? 

        <div className="partner_info_mobile">
          
        <div className="icon_partner_info_mobile">

        <MapMobile lat={0} long={0}/>

        </div>

        <div className="flex_partner_info_mobile">
        <img src={`data:image/;base64,${base64Logo}`} style={{width:'120px'}} className="icon_company_info_mobile"/>

        <img src={setCimaIcon} className="seta_icon_info_mobile" style={{width:'50px'}}/>
        <h2>{namePartner}</h2>
        </div>

        <div className="category_partner_info_mobile">
       
        <div>
        <h2>
        {services !=  '[]' ? JSON.parse(services)[0].category.category : ''} 
        </h2>
        </div>
       
        </div>

        <div className="icon_container_compan_mobile">
        <div className="icon_description_company_info_mobile">
        <img src={locationIcon} style={{width:'60px'}}/>
        <p>{adressesPartners != '[]' ? JSON.parse(adressesPartners)[0].address : ''}</p>
        </div>

        <div className="icon_description_company_info_mobile_2">
        <img src={phoneIcon2} style={{width:'40px'}}/>
        <p>11 99999-9999</p>
        </div>


        <div className="icon_description_company_info_mobile_2">
        <img src={mailIcon2} style={{width:'40px'}}/>
        <p>{mailPartner}</p>
        </div>

        <div className="icon_description_company_info_mobile_2">
        <img src={globalIcon} style={{width:'40px'}}/>
        <p>{sitePartner}</p>
        </div>

        <div className="icon_description_company_info_mobile_2">
        <img src={servicesIcon} style={{width:'40px'}}/>
        {services != '[]'  ?<p>{JSON.parse(services)[0].service}</p> : ''}
        </div>


        </div>


        <div style={{height:'220px'}}></div>

        </div>

        
        
        
        :


        array.map(item => {
        

          return(
  
              <div className="partner_info_mobile">
          
              <div className="icon_partner_info_mobile">
  
              <MapMobile lat={item.lat} long={item.long}/>
  
              </div>
      
              <div className="flex_partner_info_mobile">
              <img src={`data:image/;base64,${base64Logo}`} style={{width:'120px'}} className="icon_company_info_mobile"/>
      
              <img src={setCimaIcon} className="seta_icon_info_mobile" style={{width:'50px'}}/>
              <h2>{namePartner}</h2>
              </div>
      
              <div className="category_partner_info_mobile">
             
              <div>
              <h2>
              {services !=  '[]' ? JSON.parse(services)[0].category.category : ''} 
              </h2>
              </div>
             
              </div>
      
              <div className="icon_container_compan_mobile">
              <div className="icon_description_company_info_mobile">
              <img src={locationIcon} style={{width:'60px'}}/>
              <p>{adressesPartners != '[]' ? JSON.parse(adressesPartners)[0].address : ''}</p>
              </div>
      
              <div className="icon_description_company_info_mobile_2">
              <img src={phoneIcon2} style={{width:'40px'}}/>
              <p>11 99999-9999</p>
              </div>
      
      
              <div className="icon_description_company_info_mobile_2">
              <img src={mailIcon2} style={{width:'40px'}}/>
              <p>{mailPartner}</p>
              </div>
      
              <div className="icon_description_company_info_mobile_2">
              <img src={globalIcon} style={{width:'40px'}}/>
              <p>{sitePartner}</p>
              </div>
      
              <div className="icon_description_company_info_mobile_2">
              <img src={servicesIcon} style={{width:'40px'}}/>
              {services != '[]'  ?<p>{JSON.parse(services)[0].service}</p> : ''}
              </div>
      
      
              </div>
      
      
              <div style={{height:'220px'}}></div>
      
              </div>
  
  
          )
  
  
  
          })
  

        
        }



        


        </Carousel>

        {token == undefined ? '' :        <BottomBarMobile/>}

        </>
        

    )

}

export default PartnerInfoMobile