import React from 'react'
import iconCartaoAnjo from '../../images/iconCartaoAnjo.png'
import backIcon from '../../images/backIcon.png'
import './style.css'

const HeaderMobile = ({page}) =>{

    const redirectToPageSelect = () =>{

        window.location.href=`${page}`

    }

    return(

        <div className="header_mobile">

        <div className="icon_header_mobile">
        <img src={backIcon} onClick={redirectToPageSelect} className="back_icon_mobile"/>
        <img src={iconCartaoAnjo} className="icon_company_mobile"/>
        </div>

        </div>

    )

}

export default HeaderMobile