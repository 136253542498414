import React,{useState,useEffect} from 'react'
import './style.css'

import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'
import BottomBarMobile from '../../components/BottomBarMobile'

import mamaIcon from '../../images/mamaIcon.png'

import neuramastericon from '../../images/neuramastericon.png'

import api from '../../services/api'

const PartnersMobile = () =>{

    const [data,setData] = useState([])



    async function getDataPartners(){

        const test = await api.get('/partners')
        setData(test.data)
        }


        useEffect(() =>{
            
            getDataPartners()

        },[])

        const redirectPartnersMobile = (e) => {


            localStorage.setItem('@name_partner',e.name)
            localStorage.setItem('@phone',e.name)
            localStorage.setItem('@mail',e.email)
            localStorage.setItem('@site',e.site)
            localStorage.setItem('@service',JSON.stringify(e.services))
            localStorage.setItem('@base64Logo',e.base64Logo)



            localStorage.setItem('@address_partner',JSON.stringify(e.addresses))
            window.location.href="/partners-info-mobile"
        }




    return(
        <>
        <HeaderDashboardMobile icon={false} page="dashboard-mobile"/>

        <div className="container_partners_mobile">

        <h2>Lista de parceiros</h2>


  
        <div className="flex_partner_mobile">


        {data.map(item => {

        return(

        <>  
        <div className="card_partner_mobile" onClick={(() => redirectPartnersMobile(item))}>

        <img src={`data:image/;base64,${item.base64Logo}`}/>

        <button>Veja mais</button>

        </div>

        </>

        )

        })}

        
 



    
        </div>



        </div>

        <BottomBarMobile/>

        </>
    )

}

export default PartnersMobile