import React from 'react'
import './style.css'
const Select = ({onChange,text}) => {

    return(

        <select onChange={onChange}>
            <option value="" >{text != null ? text :'Selecione'}</option>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>

        </select>

    )
}

export default Select