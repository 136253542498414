import React,{useEffect,useState} from 'react'
import Sidebar2 from '../../components/Sidebar2'
import Header from '../../components/Header'
import Card from '../../components/Card'
import './style.css'

import api from '../../services/api'


import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'
import BottomBarMobile from '../../components/BottomBarMobile'

import mamaIcon from '../../images/mamaIcon.png'

import neuramastericon from '../../images/neuramastericon.png'


const Partners = () =>{

    const [data,setData] = useState([])
    const [token,setToken] = useState()


    async function getDataPartners(){

        const test = await api.get('/partners')
        setData(test.data)
    }


    async function getToken(){

        const token = localStorage.getItem('@TOKEN')
        setToken(token)
  
      }



        useEffect(() =>{
            getToken()
            getDataPartners()

        },[])


        const redirectPartnersMobile = (e) => {


            localStorage.setItem('@name_partner',e.name)
            localStorage.setItem('@phone',e.name)
            localStorage.setItem('@mail',e.email)
            localStorage.setItem('@site',e.site)
            localStorage.setItem('@service',JSON.stringify(e.services))
            localStorage.setItem('@base64Logo',e.base64Logo)



            localStorage.setItem('@address_partner',JSON.stringify(e.addresses))
            window.location.href="/partners-info-mobile"
        }






    return(

    <>
    <div className="container_partners">

    <div className="row_container">
    
    <div>
    <Sidebar2 checked="partners" page="partners"/>
    </div>
    
    <Header namePage="Lista de parceiros" page="partners"/>

    </div>


    <div className="column3_cards_part">

    <div className="container_cards_partners">

    
    {data.map(item => <Card key={item.id} image={item.base64Logo} addresses={item.addresses} name={item.name} servicePartner={item.services[0]} email={item.email} site={item.site} contacts={item.contacts[0]} lat={item.lat} long={item.long}  />)}

    </div>


    </div>
    </div>

    <>


        
        <div className="container_partners_mobile__2">


        {token == undefined ? <HeaderDashboardMobile icon={false} page="login"/> :<HeaderDashboardMobile icon={false} page="dashboard"/> }


        <h2>Lista de parceiros</h2>


  
        <div className="flex_partner_mobile">


        {data.map(item => {

        return(

        <>  
        <div className="card_partner_mobile" onClick={(() => redirectPartnersMobile(item))}>

        <img src={`data:image/;base64,${item.base64Logo}`}/>

        <button>Veja mais</button>

        </div>

        </>

        )

        })}

        
 



    
        </div>

        {token == undefined ? '' :<BottomBarMobile/>}

        </div>





    </>



    </>

    )
}

export default Partners