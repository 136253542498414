import React from 'react'

import handShakeIcon from '../../images/handShakeIcon.png'

const Indications  = ({balance,plan,member,status,type}) =>{


    return(

        <tr className="test3">
        <td> <img src={handShakeIcon} style={{width:'38px',marginRight:'5px'}}/>{member}</td>
        <td style={{position:'relative',top:'5px'}}>{type == 'P' ? 'Anual' : 'Mensal'}</td>
        <td style={{position:'relative',top:'5px'}}>{balance != null ?balance.toLocaleString('pt-br',{style:'currency',currency:'BRL'}) : ''}</td>
        <td style={{position:'relative',top:'5px'}}>{status == 'A'} Ativo</td>

        
        </tr>
    

    )


}

export default Indications