import React,{useEffect} from 'react'
import api from '../../services/api'
import animation from '../../images/animation_500_kzp5hrkn.gif'

import './style.css'

const JlwtLogin = () =>{

    async function getToken(){

        let response = await api.get('/recipient')

        if(response.status != 200){
            window.location.href="login"
            return false
        }
        
       
        if(response.data.message == 'The token could not be parsed from the request' || response.data.message == 'Token is expired' || response.data.message == 'Server Error'){

            localStorage.removeItem('@TOKEN')

            setTimeout(() => {

                window.location.href='login'

            }, 2000);

            return false 
        }

        if(response.data.message == null && response.data.expire !=  null){

            setTimeout(() => {

                window.location.href='dashboard'

            }, 2000);

            return false
        }
        

        if(response.data.expire == null){

            setTimeout(() => {

                window.location.href='dashboard'

            }, 2000);

            return false 
        }

    }


    useEffect(() =>{

        getToken()

    },[])


    return(


        <div className="container_jwt_token">

         <img src={animation}/>

        </div>



    )
}

export default JlwtLogin