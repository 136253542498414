import React,{useEffect, useState} from 'react'
import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'


import card2Icon from '../../images/card2Icon.png'
import pixIcon from '../../images/pixIcon.png'

import './style.css'

import InputMaskedMobile from '../../components/InputMaskedMobile'
import SelectMobile from '../../components/SelectMobile'
import ButtonMobile from '../../components/ButtonMobile'

import api from '../../services/api'

import validarCartao from 'validador-cartao-credito'


const PaymentCardMobile = () =>{

    const [card,setCard] = useState()
    const [nameCard,setNameCard] = useState()
    const [dateCard,setDateCard] = useState()

    const [cvvCard,setCvvCard] = useState()

    const [cardError,setCardError] = useState()
    const [nameError,setNameError] = useState()
    const [dueDateError,setDueDateError] = useState()

    const [cvvError,setCvvError] = useState()

    const [gif,setGif] = useState(false)
    const [memberId,setMemberId] = useState()
    const [paymentError,setPaymentError] = useState()

    const [parcelas,setParcelas] = useState()
    const [arrayCalcInstallments,setArrayCalcInstallments] = useState([])

    const [cardNumberError,setCardNumberError] = useState()

    const [cvvErrorNumber,setCvvErrorNumber] = useState()
    const [responseValidateCard,setResponseValidateCard] = useState()
    const [stateDataCard,setStateDateCard] = useState(false)

    async function getInfoUser(){

        const response = await api.get('/recipient')
        setMemberId(response.data.id)

    }

    const array = []

    const calcInstallments = () =>{

        const value_plan = localStorage.getItem('@plan_mobile_value')

        for(let i=1;i<=12;i++){

            let calc = value_plan/i

            let response = `${i}X de R$${calc.toLocaleString('pt-BR',{style:'currency',currency:'BRL'})} sem juros`

            array.push({name:response,id:i})
            

        }

        setArrayCalcInstallments(array)

    }


    async function setPayment(){

          


        if(card == null){

            setCardError('Informe o número do cartão')
        }

        if(nameCard == null){

            setNameError('Informe o titular do cartão')
        }

        if(dateCard == null){

            setDueDateError('Informe uma data de validade')
        }

        if(cvvCard == null){

            setCvvError('Informe o código de segurança')
                        
        }

        if(card != null){

            setCardError('')

        }

        if(nameCard != null){

            setNameError('')
        }

        if(dateCard != null){

            setDueDateError('')
        }

        if(cvvCard != null){

            setCvvError('')
                        
        }

        if(dateCard != null){

            console.log(dateCard.length)

        if(dateCard.length < 7){

            setCvvErrorNumber('Informe a validade no formato MM/AAAA')
            setStateDateCard(false)

            setGif(false)

        }

        if(dateCard.length == 7 ){

            setCvvErrorNumber('')
            setStateDateCard(true)
        }

        }


        console.log(stateDataCard)

        if(card != null){

            let cardFormated = card.replace(' ', '')

            let cardFormated2 = cardFormated.replace(' ', '')
            let cardFormated3 = cardFormated2.replace(' ', '')
            let cardFormated4 = cardFormated3.replace(' ', '')

            if(cardFormated4.length < 16){

                setCardError('Informe um número valido')
                return false
            }
            
            let validateCard = validarCartao(cardFormated4)
    
            setResponseValidateCard(validateCard)

                
            if(validateCard == false){
    
                setCardNumberError('Informe um número de cartão valido')
                setGif(false)
    
            }
           
            if(validateCard != false &&   cvvErrorNumber == '' && nameError == '' && stateDataCard == true){
    
                setCardNumberError('')
                setGif(true)
    
            }else{

                return false
            }
    

        }

        
        if(card == null || nameCard == null || dateCard == null || cvvCard == null || responseValidateCard == false){

            return false 

        }
        
        setGif(true)

        
        setTimeout(() =>{

            setGif(false)

        },2000)

        const plan = localStorage.getItem('@plan_mobile')
        

        const responseData = await api.post('/payment/cards',{

            

            member:memberId,
            type:'CreditCard',
            plan:plan,
            referral:'referral_link',
            descriptor:'test',
            
            dataCard: [
                {
                  cardNumber: card,
                  holder: nameCard,
                  expirationDate: dateCard,
                  securityCode:cvvCard,
                  installments:parcelas

                }
              ]
    
    
        })





       if(responseData.status == 200){

        alert('Pagameto realizado com sucesso! entraremos em contato em breve :)')

        window.location.href='/dashboard'

       }

       if(responseData.status != 200){

        setPaymentError('Não foi possivel realizar o pagamento verifique os dados e tente novamente')

       }

       }

       useEffect(() =>{

        getInfoUser()
        calcInstallments()
       },[])


    return(

        <>
        <HeaderDashboardMobile icon={false} page="plans-mobile"/>
        
        <div className="payment_card_mobile">

        <div className="select_type_payment_card_mobile">

        <div className="card_option_payment_card_mobile">
        <img src={card2Icon}/>
        <p>Cartão</p>
        </div>

        <div className="pix_option_payment_card_mobile" onClick={() => window.location.href="/payment-pix-mobile"}>
        <img src={pixIcon}/>
        </div>
        </div>

        <div className="input_container_payment_card_mobile">

        {paymentError != null ? <p className="error_payment_mobile">{paymentError}</p> : ''}

        <div>
        <label>Número do cartão</label>
        <InputMaskedMobile mask="9999 9999 9999 9999"  type="tel" onChange={(e) => setCard(e.target.value)}  />
        {cardError != null ? <p className="error_payment_mobile">{cardError}</p> : ''}
        {cardNumberError != null ? <p className="error_payment_mobile">{cardNumberError}</p> : ''}

        </div>

        <div>
        <label>Nome no cartão</label>
        <InputMaskedMobile  onChange={(e) => setNameCard(e.target.value)} />
        {nameError != null ? <p className="error_payment_mobile">{nameError}</p> : ''}

        </div>

        <div className="flex_card_option_mobile">

        <div>
        <label>Data de venc</label>
        <InputMaskedMobile type="tel" mask="99/9999"  onChange={(e) => setDateCard(e.target.value)} />
        {dueDateError != null ? <p className="error_payment_mobile">{dueDateError}</p> : ''}
        {cvvErrorNumber != null ? <p className="error_payment_mobile">{cvvErrorNumber}</p> : ''}

        </div>

        <div>
        <label>Código cvc</label>
        <InputMaskedMobile type="tel" mask="999"  onChange={(e) => setCvvCard(e.target.value)} />
        {cvvError != null ? <p className="error_payment_mobile">{cvvError}</p> : ''}
        </div>

        </div>

        <div className="input_container_payment_card_mobile_select">
        <label>Número de parcelas</label>
        <select onChange={(e) => setParcelas(e.target.value)}  >
        {arrayCalcInstallments.map(item => {

        return <option value={item.id}>{item.name}</option>

        })}
        </select>
        </div>


        <div className="payment_card_mobile_button">
        <ButtonMobile text="Assinar plano" gif={gif} onClick={setPayment} />
        </div>

        </div>

     
        </div>

        
        </>


    )

}

export default PaymentCardMobile