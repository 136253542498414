import React,{useEffect,useState} from 'react'

import DashboardsMobile from '../../components/Dashboards-Mobile'
import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'
import BottomBarMobile from '../../components/BottomBarMobile'

import HistoricTransactionsMobileComponent from '../../components/HistoricTransactionsMobileComponent'

import './style.css'
import Api from '../../services/api'
import verifyUser from '../../functions/verifyUser'

const HistoricTransactionsMobile = () =>{

    useEffect(() =>{

        verifyUser()


    },[])



    const [data,setTransactions] = useState([])

    async function getDataIndications(){

        const data = await Api.get('/payment')
        if(data.status != 200){
            return false
        }
        
        setTransactions(data.data)
    }

    useEffect(() =>{

        getDataIndications()

    },[])

    return(

        <>
        <HeaderDashboardMobile page="dashboard" />

        <div className="historic_transactions_dashboard_mobile">
        <h2>Perfil</h2>
        <div className="container_dashboards_historic_transactions_mobile">
        <DashboardsMobile indications={false}/>
        
        <div className="data_historic_transactions">

        <p>Historico de transações</p>
        
        {data.map(item => 
        
        <HistoricTransactionsMobileComponent  key={item.id} value={item.value} date={item.updated_at} plan={item.plan_id}  type={item.plan.type} situation={item.confirmation} />)}



        </div>

        </div>


        </div>
        <BottomBarMobile/>

        </>

    )

}

export default HistoricTransactionsMobile