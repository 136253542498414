import React from 'react'
import './style.css'
const Input = ({type,placeholder,onChange}) =>{

    return(

        <input type={type} placeholder={placeholder} onChange={onChange} />

    )

}

export default Input