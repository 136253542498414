import React, { useState } from 'react'

import iconCartaoAnjo from '../../images/iconCartaoAnjo.png'

import './style.css'

import Input from '../../components/Input'

import ButtonMobile from '../../components/ButtonMobile'
import api from '../../services/api'

import HeaderMobile from '../../components/HeaderMobile'
import FacebookLogin from 'react-facebook-login';
import facebookIcon from '../../images/facebookIcon.png'

const LoginMobile = () => {
  const [mail, setMail] = useState()
  const [mailMobile, setMailMobile] = useState()
  const [passwordMobile, setPasswordMobile] = useState()

  const [password, setPassword] = useState()
  const [messageError, setMessageErrorLogin] = useState()
  const [gif, setGif] = useState(false)

  const [mailError, setMailError] = useState()
  const [passwordError, setPasswordError] = useState()

  async function logInMobile() {
    if (mail == null) {
      setMailError('Informe um email')
    }

    if (password == null) {
      setPasswordError('Informe uma senha')
    }

    if (password == null || mail == null) {
      return false
    }

    setGif(true)

    const response = await api.post('/login', {
      email: mail,
      password: password
    })

    if (response) {
      setGif(false)
    }

    if (response.status == 200) {
      localStorage.setItem('@TOKEN', response.data.access_token)
      setMessageErrorLogin(null)
      setMail('')
      setPassword('')
      const data = await api.get('/recipient')

      let verifyUserExistsInDatabase = data.data.expire

      if (verifyUserExistsInDatabase) {
        window.location.href = '/dashboard'
        return false
      }

      if (!verifyUserExistsInDatabase) {
        window.location.href = '/dashboard'
        return false
      }
    }

    if (response.status == 401) {
      setMessageErrorLogin(
        'Desculpe, endereço de e-mail ou senha incorretos. Por favor, tente novamente'
      )
    }
  }

  async function responseFacebook(response){

    const responseFromApi = await api.post('/login/social',{
      
      provider: "facebook",
      access_token: response.accessToken
  })


  if(responseFromApi.status == 200){

    localStorage.setItem('@TOKEN',responseFromApi.data.access_token)
    window.location.href="/dashboard"

  }

  }


  return (
    <div className="login_mobile">
      <HeaderMobile page="login" />

      <h2>Dados de acesso</h2>

      {messageError != null ? (
        <p className="login_invalid_login_mobile">
          Desculpe, endereço de e-mail <br /> ou senha incorretos. Por favor,
          tente novamente
        </p>
      ) : (
        ''
      )}

      <div className="container_inputs_login_mobile">
        <Input placeholder="E-mail" onChange={e => setMail(e.target.value)} />
        {mailError != null ? (
          <p className="message_error_login_mobile">{mailError}</p>
        ) : (
          ''
        )}

        <Input
          placeholder="Senha"
          type="password"
          onChange={e => setPassword(e.target.value)}
        />
        {passwordError != null ? (
          <p className="message_error_login_mobile">{passwordError}</p>
        ) : (
          ''
        )}

        <div className="row_container_inputs_login_mobile">
          <div></div>
          <p onClick={() => (window.location.href = '/forgot-password-mobile')}>
            Esqueceu a senha?
          </p>
        </div>

        <ButtonMobile
          text="Entrar"
          gif={gif}
          onClick={logInMobile}
          lock={true}
        />

            <div className="arrow_container_mobile">
              <svg width="125" height="1" viewBox="0 0 185 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="185" y2="0.5" stroke="#CFCFCF"/>
              </svg>
              <p>OU</p>

              <svg width="125" height="1" viewBox="0 0 185 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="185" y2="0.5" stroke="#CFCFCF"/>
              </svg>

              </div>

              <div className="container_button_facebook_mobile">
              <FacebookLogin
              cssClass="facebook_button_mobile"
              appId="563818371558210"
              autoLoad={false}
              textButton="Entrar com o facebook"
              fields="name,email,picture"
              callback={() => responseFacebook} />
              <img src={facebookIcon}/>
            </div>

        <div className="footer_mobile">
          <h2>Cartão Anjo: v6.9 &copy;</h2>
        </div>
      </div>
    </div>
  )
}

export default LoginMobile
