import React from 'react'
import './style.css'
import gifIcon from '../../images/loader.gif'
import lockIcon from '../../images/lockIcon.png'

const ButtonMobile = ({text,onClick,gif,width,lock}) =>{

    return(

        <button className="button_mobile" style={{width:width}} onClick={onClick}>
        {gif == true ? <img src={gifIcon} style={{width:'40px'}}/> 
        :

        

        lock == true 
        ?
       
        <div className="button_mobile_container_buttons">
        <img src={lockIcon}/>
        {text}
        </div>
        :

        <div className="button_mobile_container_buttons">
        {text}
        </div>

        

       
        
        
        }
        
        </button>

    )

}

export default ButtonMobile